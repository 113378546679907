export const languages = [
  {
    name: 'localize.english',
    locale: 'en'
  },
  {
    name: 'localize.vietnamese',
    locale: 'vi'
  }
]
