import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import './lib/css'
import GlobalComponents from './gloabalComponents'
import MenuIcon from 'vue-material-design-icons/Menu';
import GhostIcon from 'vue-material-design-icons/Ghost';
import store from '@/store'
import VueI18n from 'vue-i18n'
import messages from './lang'
import datePicker from 'vue-bootstrap-datetimepicker'
Vue.use(datePicker)
// eslint-disable-next-line no-undef
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
})
Vue.use(VueI18n)
// creating a instance of vue localisation module
const i18n = new VueI18n({
  locale: store.getters.selectedLocale,
  messages
})
Vue.component('menu-icon', MenuIcon);
Vue.component('ghost-icon', GhostIcon);
Vue.config.productionTip = false
// Bootstrap vue
Vue.use(BootstrapVue)
// Global components
Vue.use(GlobalComponents)
new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')