import common from "Api";

const state = {
    eventDetails: [],
    meetingResources: [],
    eventUserRegistered: [],
    userInEventSession: [],
    numberValueForSidebar: []
}

// Getter
const getters = {
    EVENT_DETAIL_DATA: state => {
        return state.eventDetails;
    },
    GET_MEETING_RESOURCE_IN_EVENT_DATA: state => {
        return state.meetingResources;
    },
    GET_EVENT_USER_REGISTERED_DATA: state => {
        return state.eventUserRegistered;
    },
    GET_USER_IN_EVENT_SESSION_DATA: state => {
        return state.userInEventSession;
    },
    GET_NUMBER_VALUE_FOR_SIDEBAR_DATA: state => {
        return state.numberValueForSidebar;
    }
}
// Action
const actions = {
    GET_EVENT_DETAIL: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                `api/v1/events/${payload.id}/`,
                payload,
                function (res) {
                    context.commit("GET_EVENT_DETAIL", res.data)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_MEETING_RESOURCE_IN_EVENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                `api/v1/agendas/get_meeting_resource_in_event/`,
                payload,
                function (res) {
                    context.commit("GET_MEETING_RESOURCE_IN_EVENT", res.data)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    CREATE_AGENDA_IN_EVENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                `api/v1/agendas/create_agenda_in_event/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_EVENT_USER_REGISTERED: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                `api/v1/events/event_user_registered_for_meeting/`,
                payload,
                function (res) {
                    context.commit("GET_EVENT_USER_REGISTERED", res.data)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_USER_IN_EVENT_SESSION: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                `api/v1/agendas/get_user_in_event_session/`,
                payload,
                function (res) {
                    context.commit("GET_USER_IN_EVENT_SESSION", res.data)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    UPDATE_USER_IN_EVENT_SESSION: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                `api/v1/agendas/update_user_in_event_session/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_NUMBER_VALUE_FOR_SIDEBAR: (context, payload) => {
      return new Promise((resolve, reject) => {
          common.getRequest(
              `api/v1/events/get_number_value_for_sidebar_meeting_list/`,
              payload,
              function (res) {
                  context.commit("GET_NUMBER_VALUE_FOR_SIDEBAR", res.data)
                  resolve(res)
              },
              function (error) {
                  reject(error)
              }
          )
      })
    }
}
// Mutations
const mutations = {
    GET_EVENT_DETAIL: (state, payload) => {
        state.eventDetails = payload;
    },
    GET_MEETING_RESOURCE_IN_EVENT: (state, payload) => {
        state.meetingResources = payload
    },
    GET_EVENT_USER_REGISTERED: (state, payload) => {
        let event_user_registered = payload.results != undefined ? payload.results : payload;
        // Paginate
        if (payload.page != undefined) {
            let page = {
                totalPage: payload.num_pages,
                currentPage: payload.page,
                pageSize: payload.page_size,
                perPage: payload.per_page,
                total: payload.count
            }
            state.eventUserRegistered = { results: event_user_registered, paginate: page };
        } else {
            state.eventUserRegistered = event_user_registered;
        }
    },
    GET_USER_IN_EVENT_SESSION: (state, payload) => {
        state.userInEventSession = payload
    },
    GET_NUMBER_VALUE_FOR_SIDEBAR: (state, payload) => {
        state.numberValueForSidebar = payload
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}