<template>
  <div>
    <b-modal
      v-model="isShow"
      :title="$t('common.confirm')"
      centered
      id="modal-center"
    >
      <div class="container">
        <div class="row">
          <div
            class="col-md-12 mb-2"
            style="padding: 0 !important; margin-top: 0 !important;"
          >
            <span>{{ $t('document.currentDigitalSigner') }}: </span>
            <div style="display: block;">
              <div
                v-for="(approverName, index) in approversName"
                :key="approverName + index"
                style="display: inline-block;"
              >
                <div
                  class="mr-1 mt-1"
                  style="padding: 0px 4px 4px 4px; background-color: #41b883; color: #fff; border-radius: 5px;"
                >
                  <span class="meeting-font-medium">
                    {{ approverName.full_name }}
                  </span>
                  <span
                    @click="removeCurrentViewer(approverName)"
                    class="meeting-font-small"
                    style="background-color: #369a6e; padding: 2px 5px 2px 5px; cursor: pointer; border-radius: 5px;"
                    >X</span
                  >
                </div>
              </div>
            </div>
          </div>
          <p>{{ $t('document.addNewDigitalSigner') }}</p>
          <div class="col-md-12" style="padding: 0 !important;">
            <VueMutiselect
              ref="approverMutiSelect"
              :optionData="approverList"
              :customLabel="customLabel"
              :labelText="labelTextValue"
              :trackByText="trackByTextValue"
              :placeHolder="$t('mission.selectPerson')"
              @select="getAssigner"
            ></VueMutiselect>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right btn-submit ml-3"
            style="border: none;"
            @click="onConfirm()"
          >
            {{ $t("common.accept") }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            style="background-color: #fff; color: #000; border: none;"
            @click="isShow = false"
          >
            {{ $t("common.close") }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VueMutiselect from "Components/VueMutiselect";
import functionUtils from "utils/functionUtils";
// import DocumentStatus from "enum/documentStatus";
import EventSessionType from 'enum/eventSessionType'
import stringUtils from 'utils/stringUtils'
import axios from "axios";
import Enum from 'enum/enum'
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'
export default {
  components: {
    VueMutiselect,
  },
  data: () => ({
    isShow: false,
    approverList: [],
    labelTextValue: "full_name",
    trackByTextValue: "full_name",
    approverSelected: [],
    eventId: null,
    documentId: null,
    isUpdate: false,
    approversName: [],
    documentFile: null,
    mediaServerToken: null,
    mediaServerDomain: null,
    documentFileName: null,
    event:{
      name: ''
    },
    approversVueMutiselect: []
  }),

  computed: {
    ...mapGetters(["GET_USER_IN_EVENT_LIST_DATA", "GET_SNACK_BAR", "GET_TOKEN_MEDIA_SERVER_DATA", "EVENT_DETAIL_DATA"]),
  },
  watch: {
    GET_TOKEN_MEDIA_SERVER_DATA: function() {
      this.mediaServerToken = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token;
      this.mediaServerDomain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain;
    },
    EVENT_DETAIL_DATA: function(){
      this.event.name = this.EVENT_DETAIL_DATA.name;
    }
  },
  created() {
    this.GET_TOKEN_MEDIA_SERVER();
    this.eventId = sessionStorage.getItem("event_id");
    let event = {
      params:{
        is_meeting: true
      },
      id: this.eventId
    }
    this.GET_EVENT_DETAIL(event)
  },
  methods: {
    /**
     * Remove current viewer
     */
    removeCurrentViewer: function(viewer) {
      for (let i = 0, size = this.approversName.length; i < size; i++) {
        if (viewer.id == this.approversName[i].id) {
          this.approversName.splice(i, 1);
          this.approverSelected = this.approverSelected.filter((item) => {
            return item.id != viewer.id;
          });
          break;
        }
      }
      this.approverList.push(viewer);
    },
    /**
     * Get attendees speakers
     */
    getUsersInEvent: function() {
      // Get viewer document
      if (this.isUpdate) {
        let filter = {
          params: {
            documentId: this.documentId,
          },
        };
        this.GET_DOCUMENT_APPROVER(filter).then(
          function(res) {
            let data = res.data;
            this.approverSelected = [];
            this.approversName = [];
            for (let i = 0, size = data.length; i < size; i++) {
              if (!functionUtils.isNull(data[i].user)) {
                this.approverSelected.push({
                  id: data[i].user.id,
                  is_group: data[i].is_group ? data[i].is_group : false,
                });
                // Get full name
                let fullname =
                  data[i].user.last_name + " " + data[i].user.first_name;
                // Get id
                let id = data[i].user.id;
                let url_avatar =
                  data[i].user.profile && data[i].user.profile.url_avatar;
                let is_group = data[i].is_group ? data[i].is_group : false;
                this.approversName.push({
                  id: id,
                  full_name: fullname,
                  url_avatar: url_avatar,
                  is_group: is_group,
                });
              }
            }
            // Get user in event list
            let attendesSpeakersFilter = {
              params: {
                event: this.eventId,
                bandGroup: true,
              },
            };
            this.GET_USER_IN_EVENT_LIST(attendesSpeakersFilter).then(
              function(res) {
                let data = res.data;
                // Get user in event
                this.approverList = [];
                if (!functionUtils.isNull(data)) {
                  data.forEach((element) => {
                    let full_name =
                      element.last_name + " " + element.first_name;
                    let item = {
                      id: element.id,
                      full_name: full_name,
                      url_avatar:
                        (element.profile &&
                          element.profile.url_avatar != null) ||
                        element.profile.url_avatar != ""
                          ? element.profile.url_avatar
                          : null,
                      is_group: element.is_group,
                    };
                    let isExist = false;
                    for (
                      let i = 0, size = this.approversName.length;
                      i < size;
                      i++
                    ) {
                      if (this.approversName[i].id == item.id) {
                        isExist = true;
                      }
                    }
                    if (!isExist) {
                      this.approverList.push(item);
                    }
                  });
                }
              }.bind(this)
            );
          }.bind(this)
        );
      } else {
        this.approverSelected = [];
        this.approversName = [];
        // Get user in event list
        let attendesSpeakersFilter = {
          params: {
            event: this.eventId,
            bandGroup: true,
          },
        };
        this.GET_USER_IN_EVENT_LIST(attendesSpeakersFilter).then(
          function(res) {
            let data = res.data;
            // Get user in event
            this.approverList = [];
            if (!functionUtils.isNull(data)) {
              data.forEach((element) => {
                let full_name = element.last_name + " " + element.first_name;
                let item = {
                  id: element.id,
                  full_name: full_name,
                  url_avatar:
                    (element.profile && element.profile.url_avatar != null) ||
                    element.profile.url_avatar != ""
                      ? element.profile.url_avatar
                      : null,
                  is_group: element.is_group,
                };
                this.approverList.push(item);
              });
            }
          }.bind(this)
        );
      }
    },
    /**
     * Get uploader
     */
    getAssigner(data) {
      this.approversVueMutiselect = []
      data.forEach((item) => {
        this.approversVueMutiselect.push({ id: item.id, is_group: item.is_group });
      });
    },
    /**
     * Customize label
     */
    customLabel({ full_name }) {
      return `${full_name}`;
    },
    // On confirm
    onConfirm: function() {
      if (this.isUpdate) {
        let filter = {
          documentId: this.documentId,
          approvers: this.approverSelected.concat(this.approversVueMutiselect)
        }
        this.UPDATE_DOCUMENT_APPROVER(filter).then(
          function () {
            this.sendNotificationSummanyAssign()
            this.approversVueMutiselect= [];
            this.ON_SHOW_TOAST({
              message: this.$t("common.success"),
              styleType: "success",
            });
            this.isShow = false
          }.bind(this)
        )
      } else {
        this.uploadFileToServer()
      }
    },
    /**
     * Upload file to server
     */
    uploadFileToServer: function() {
      // Invalid if file bigger 10MB
      if (this.documentFileSize > 10000000) {
        this.ON_SHOW_TOAST({
          message: this.$t("document.cannotExceed10MB"),
          styleType: "danger",
        });
      } else {
        let formData = new FormData();
        formData.append("file", this.documentFile);
        formData.append("directory", "documents/" + this.eventId);
        axios({
          method: "POST",
          timeout: 6000,
          url: this.mediaServerDomain + "/api/v1/upload/",
          data: formData,
          headers: { Authorization: `JWT ${this.mediaServerToken}` },
        })
          .then(
            function(res) {
              let url = res.data.domain + res.data.url;
              let filter = {
                name: this.documentFileName,
                url: url,
                event: this.eventId,
                document_status: 1,
                approvers: this.approverSelected,
                eventSessionType: EventSessionType.SUMMARY,
                title: stringUtils.EVENT_SESSION_SUMMARY_TITLE
              };
              this.SAVE_DOCUMENT_FOR_REPORT_MEETING(filter)
                .then(
                  function() {
                    this.ON_SHOW_TOAST({
                      message: this.$t("common.success"),
                      styleType: "success",
                    });
                    this.sendNotificationSummanyAssign() 
                    this.documentFileName = null;
                    this.approverSelected = [];
                    this.$refs.approverMutiSelect.clearAll();
                    this.isShow = false
                  }.bind(this)
                )
                .catch(
                  function() {
                    this.ON_SHOW_TOAST({
                      message: this.$t("common.somethingWentWrong"),
                      styleType: "danger",
                    });
                  }.bind(this)
                );
            }.bind(this)
          )
          .catch(
            function() {
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger",
              });
            }.bind(this)
          );
      }
    },
    /**
     * On show modal confirm
     */
    onShowModalConfirm: function(documentFile, documentFileName, isUpdate, documentSelected) {
      this.documentId = documentSelected && documentSelected.id
      this.isUpdate = isUpdate
      this.documentFile = documentFile;
      this.documentFileName = documentFileName
      this.getUsersInEvent();
      this.isShow = true;
    },
    sendNotificationSummanyAssign(){
      let userList = [];
      let groupList = [];
      let approverList = this.approverSelected.concat(this.approversVueMutiselect);
      approverList.forEach(data =>{
        if(data.is_group && groupList.includes(data.id)==false){
          groupList.push(data.id)
        }else if(data.is_group === false && userList.includes(data.id)==false){
          userList.push(data.id)
        }
      })
      let notificationData = {
        event_id: this.eventId,
        // message_title: this.event.name,
        // message_body: this.$t('notification.summary.reportMeeting', {'0': this.event.name}),
        user_list: userList,
        group_list: groupList,
        action_code: TemplateCategoriesEnum.CONFIRM_CONCLUSION,
        template_type: Enum.TemplateType.Notification,
        notification_type_code: Enum.NotificationType.ConfirmReportMeeting
      }
      this.SEND_NOTIFICATION_IN_MEETING(notificationData)

      // send sms
      // let smsContent = this.$t('notification.summary.reportMeeting', {'0': this.event.name})

      let smsData = {
        event_id: this.eventId,
        // content: functionUtils.uniDecodeString(smsContent),
        user_list: userList,
        group_list: groupList,
        action_code: TemplateCategoriesEnum.CONFIRM_CONCLUSION,
        template_type: Enum.TemplateType.SMS,
      }
      this.SEND_SMS_IN_MEETING(smsData)
    },
    sendEmail: function(){
      let userList = [];
      let groupList = [];
      let approverList = this.approverSelected.concat(this.approversVueMutiselect);
      approverList.forEach(data =>{
        if(data.is_group && groupList.includes(data.id)==false){
          groupList.push(data.id)
        }else if(data.is_group === false && userList.includes(data.id)==false){
          userList.push(data.id)
        }
      })
      let data = {
        action_code: TemplateCategoriesEnum.CONFIRM_CONCLUSION,
        template_type: Enum.TemplateType.Email,
        event_id: this.eventId,
        user_list: userList,
        group_list: groupList,
      }
      this.SEND_EMAIL_WITH_TEMPLATE_CONFIG(data)
    },
    ...mapActions([
      "GET_USER_IN_EVENT_LIST",
      "ADD_DOCUMENT_APPROVER",
      "ON_SHOW_TOAST",
      "UPDATE_DOCUMENT_APPROVER",
      "GET_DOCUMENT_APPROVER",
      "GET_TOKEN_MEDIA_SERVER",
      "SAVE_DOCUMENT_FOR_REPORT_MEETING",
      "GET_EVENT_DETAIL",
      "SEND_NOTIFICATION_IN_MEETING",
      "SEND_SMS_IN_MEETING",
      "SEND_EMAIL_WITH_TEMPLATE_CONFIG"
    ]),
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.btn-submit {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  border-radius: 5px;
}
</style>
