import common from "Api/index";
import {
  isCookieEnabled,
  setCookie,
  removeCookie
} from "tiny-cookie";
// state
const state = {
  currentUser: null,
  login: {},
  attendees: [],
  speakers: [],
  events: [],
  eventSessions: [],
  userDetail: {},
  attendeesSpeakerList: [],
  userNotInEvent: [],
  reasonAbsence: [],
  tokenMediaServer: [],
  attendanceNumber: [],
  userList: [],
  chairman: [],
  eventSessionsByRole: [],
  user_band_group: [],
  user_in_band_group_list: [],
  band_group_in_event_list: []
}

// getters
const getters = {
  GET_CURRENT_ACCOUNT: state => {
    return state.login
  },
  GET_USER_DATA: state => {
    return state.users
  },
  // Attendee
  GET_ATTENDEE_DATA: state => {
    return state.attendees;
  },
  GET_SPEAKER_DATA: state => {
    return state.speakers;
  },
  GET_EVENT_WITH_DATE_DATA: state => {
    return state.events
  },
  GET_EVENT_SESSIONS_DATA: state => {
    return state.eventSessions
  },
  GET_USER_DETAIL_DATA: state => {
    return state.userDetail
  },
  GET_USER_IN_EVENT_LIST_DATA: state => {
    return state.attendeesSpeakerList
  },
  GET_USER_NOT_IN_EVENT_DATA: state => {
    return state.userNotInEvent
  },
  GET_REASON_ABSENCE_DETAIL_DATA: state => {
    return state.reasonAbsence
  },
  GET_TOKEN_MEDIA_SERVER_DATA: state => {
    return state.tokenMediaServer
  },
  GET_ATTENDANCE_NUMBER_DATA: state => {
    return state.attendanceNumber
  },
  GET_USER_LIST_DATA: state => {
    return state.userList
  },
  GET_CHAIRMAN_FOR_MEETING_DATA: state => {
    return state.chairman
  },
  GET_EVENT_SESSIONS_BY_ROLE_DATA: state => {
    return state.eventSessionsByRole
  },
  GET_BAND_GROUP_LIST_DATA: state => {
    return state.user_band_group;
  },
  GET_USER_IN_BAND_GROUP_DATA: state => {
    return state.user_in_band_group_list;
  },
  GET_BAND_GROUP_IN_EVENT_DATA: state => {
    return state.band_group_in_event_list;
  },
}

// actions
const actions = {
  SET_USER_DATA: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        'api/v1/auth/userinfo/',
        payload,
        function (res) {
          context.commit('SET_USER_DATA_HANDLER', res.data)
          if (isCookieEnabled()) {
            setCookie('role', res.data.response.group_id)
            resolve(res)
          } else {
            reject(res)
          }
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  LOGIN: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        "api/v1/auth/jwt-auth/",
        {
          username: payload.username,
          password: payload.password
        },
        function (res) {
          context.commit("LOGIN_USER_HANDLER", res.data);
          if (isCookieEnabled()) {
            setCookie("token", res.data.response.token);
            resolve(res);
          } else {
            reject(res);
          }
        },
        function (error) {
          if (isCookieEnabled()) {
            removeCookie("token");
          }
          reject(error);
        }
      );
    });
  },
  LOGOUT: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        'api/v1/auth/jwt-refresh/',
        {
          token: payload
        },
        function (res) {
          context.commit('LOGOUT', res.data)
          if (isCookieEnabled()) {
            removeCookie('token')
            resolve(res)
          } else {
            let error = ''
            reject(error)
          }
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REGISTER: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        'api/v1/meeting/signup/',
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SEND_REGISTERED_ACCOUNT_FOR_ICT_DONGNAI: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        `api/v1/meeting/signup/${payload.id}/send_email_registered_account/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SEND_EMAIL_ACTIVE_ACCOUNT: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        `api/v1/meeting/signup/${payload.id}/re_send_activate_email_account/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ACCOUNT_EMAIL_ACTIVATED: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        `/api/v1/accounts/activated/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  // Attendee
  GET_ATTENDEE_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        "api/v1/customers/",
        payload,
        function (res) {
          context.commit("GET_ATTENDEE_LIST", res.data);
          resolve(res);
        },
        function (error) {
          reject(error);
        }
      );
    });
  },
  // Speaker
  GET_SPEAKER_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        "api/v1/speakers/",
        payload,
        function (res) {
          context.commit("GET_SPEAKER_LIST", res.data);
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_EVENT_WITH_DATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        'api/v1/events/get_event_with_date/',
        payload,
        function (res) {
          context.commit('GET_EVENT_WITH_DATE', res.data)
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_EVENT_SESSION: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        `api/v1/agendas/get_event_session/`,
        payload,
        function (res) {
          context.commit('GET_EVENT_SESSION', res.data)
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SET_FAVORITE_USER_IN_EVENT_SESSION: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        `api/v1/agendas/set_favorite_user_in_event_session/`,
        payload,
        function (res) {
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_USER_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        `api/v1/users/${payload.id}/`,
        payload,
        function (res) {
          context.commit('GET_USER_DETAIL', res.data)
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  
  GET_USER_IN_EVENT_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        "api/v1/attendance/get_user_in_event/",
        payload,
        function (res) {
          context.commit("GET_USER_IN_EVENT_LIST", res.data);
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_USER_PROFILE: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.putRequest(
        `api/v1/users/${payload.id}/`,
        payload,
        function (res) {
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_USER_NOT_IN_EVENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        "api/v1/attendance/get_all_user_not_in_event/",
        payload,
        function (res) {
          context.commit("GET_USER_NOT_IN_EVENT", res.data);
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_ATTENDANCE_STATUS: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        "api/v1/attendance/update_attendance_status/",
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ADD_REASON_ABSENCE: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        "api/v1/reason_absence/",
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_REASON_ABSENCE: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.putRequest(
        `api/v1/reason_absence/${payload.id}/`,
        payload,
        function (res) {
          resolve(res);
        },
        function (error) {
          reject(error);
        }
      );
    })
  },
  GET_REASON_ABSENCE_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        `/api/v1/reason_absence/get_reason_absence_detail/`,
        payload,
        function (res) {
          context.commit("GET_REASON_ABSENCE_DETAIL", res.data);
          resolve(res.data);
        },
        function (error) {
          reject(error);
        }
      );
    })
  },
  GET_TOKEN_MEDIA_SERVER: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        `/api/v1/users/get_token_media_server/`,
        payload,
        function (res) {
          context.commit("GET_TOKEN_MEDIA_SERVER", res.data);
          resolve(res.data);
        },
        function (error) {
          reject(error);
        }
      );
    })
  },
  ADD_USER_INTO_MEETING: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        "api/v1/accounts/add_account_in_event/",
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ATTENDANCE_NUMBER: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        `/api/v1/attendance/get_attendance_number/`,
        payload,
        function (res) {
          context.commit("GET_ATTENDANCE_NUMBER", res.data);
          resolve(res.data);
        },
        function (error) {
          reject(error);
        }
      );
    })
  },
  GET_USER_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        "api/v1/users/",
        payload,
        function (res) {
            context.commit("GET_USER_LIST", res.data);
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ACCOUNT_PASSWORD_RESET: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        `/api/v1/passwordreset/`,
        payload,
        function (res) {
          resolve(res);
        },
        function (error) {
          reject(error);
        },
        600000
      );
    });
  },
  ACCOUNT_PASSWORD_RESET_SET_PASSWORD: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        `/api/v1/accounts/password_reset/set_password/`,
        payload,
        function (res) {
          resolve(res);
        },
        function (error) {
          reject(error);
        }
      );
    });
  },
  GET_CHAIRMAN_FOR_MEETING: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        `/api/v1/attendance/get_chairman_for_meeting/`,
        payload,
        function (res) {
          context.commit("GET_CHAIRMAN_FOR_MEETING", res.data);
          resolve(res.data);
        },
        function (error) {
          reject(error);
        }
      );
    })
  },
  GET_EVENT_SESSION_BY_ROLE: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        `api/v1/agendas/get_event_session_by_role/`,
        payload,
        function (res) {
          context.commit('GET_EVENT_SESSION_BY_ROLE', res.data)
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CHECK_USER_IN_EVENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        `api/v1/users/check_user_in_event/`,
        payload,
        function (res) {
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REGISTER_USER_IN_EVENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        `api/v1/users/register_user_in_event/`,
        payload,
        function (res) {
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_BAND_GROUP_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        "api/v1/user_band_group/",
        payload,
        function (res) {
          context.commit("GET_BAND_GROUP_LIST", res.data);
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_BAND_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        "api/v1/user_band_group/",
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_BAND_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.putRequest(
        `api/v1/user_band_group/${payload.id}/`,
        payload,
        function (res) {
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_BAND_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.deleteRequest(
        `api/v1/user_band_group/${payload.id}/`,
        {},
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REMOVE_USER_FROM_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        "api/v1/user_band_group/remove_user_from_group/",
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_USER_IN_BAND_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        `api/v1/user_band_group/get_user_in_group/`,
        payload,
        function (res) {
          context.commit("GET_USER_IN_BAND_GROUP", res.data);
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ADD_USER_INTO_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        "api/v1/user_band_group/add_user_into_group/",
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ALL_ACCOUNT: (context, payload) => {
    return new Promise((resolve, reject) => {
        common.getRequest(
            "api/v1/allaccountlist/",
            payload,
            function (res) {
                resolve(res)
            },
            function (error) {
                reject(error)
            }
        )
    })
  },
  GET_BAND_GROUP_IN_EVENT: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        "api/v1/user_band_group/get_band_group_in_event/",
        payload,
        function (res) {
          context.commit("GET_BAND_GROUP_IN_EVENT", res.data);
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_HISTORY_LOG: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        "api/v1/users/create_history_log/",
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_HISTORY_LOG: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        "api/v1/history_log/",
        payload,
        function (res) {
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CHECK_CURRENT_USER_NO_ATTENDANCE: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        "api/v1/attendance/check_current_user_no_attendance/",
        payload,
        function (res) {
          resolve(res);
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  LOGIN_USER_HANDLER: (state, payload) => {
    state.currentUser = payload
  },
  SET_USER_DATA_HANDLER: (state, payload) => {
    let userData = {
      id: '',
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      lastlogin: '',
      group_id: '',
      group_name: '',
      privilege_id: '',
      privilege_name: '',
      url_avatar: '',
      permissions: '',
      role: '',
      company_name: '',
      company_position: '',
      attendance: ''
    }
    userData.id = payload.response && payload.response.id
    userData.username = payload.response && payload.response.username
    userData.firstname = payload.response && payload.response.firstname
    userData.lastname = payload.response && payload.response.lastname
    userData.email = payload.response && payload.response.email
    userData.lastlogin = payload.response && payload.response.last_login
    userData.group_id = payload.response && payload.response.group_id
    userData.group_name = payload.response && payload.response.group_name
    userData.privilege_id = payload.response && payload.response.attended_event
    userData.privilege_name = payload.response && payload.response.privilege_name
    userData.url_avatar = payload.response && payload.response.url_avatar
    userData.permissions = payload.response && payload.response.permissions
    userData.phone = payload.response && payload.response.phone
    userData.company_name = payload.response && payload.response.company_name
    userData.company_position = payload.response && payload.response.company_position
    userData.qr_code = payload.response && payload.response.qr_code,
    userData.role = payload.response && payload.response.role
    userData.attendance = payload.response && payload.response.attendance
    // Set state for getter
    state.login = userData
  },
  LOGOUT: (state) => {
    state.login = {
      id: '',
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      lastlogin: '',
      group_id: '',
      group_name: '',
      role: '',
      privilege_id: '',
      privilege_name: '',
      url_avatar: '',
      permissions: []
    }
  },
  // Attendee
  GET_ATTENDEE_LIST: (state, payload) => {
    let customer_data = payload.results != undefined ? payload.results : payload;
    let customer_list = [];

    customer_data.forEach(value => {
      let customer_info = {
        id: value.id,
        username: value.username,
        firstname: value.first_name,
        lastname: value.last_name,
        date_joined: value.date_joined,
        email: value.email,
        groups: value.groups,
        privilege: value.privilege,
        checkin_status: value.checkin_status,
        attendance: value.attendance
      };

      if (value.profile != null) {
        customer_info.phone = value.profile.phone,
          customer_info.code = value.profile.code,
          customer_info.company_name = value.profile.company_name,
          customer_info.company_position = value.profile.company_position,
          customer_info.gender = value.profile.gender,
          customer_info.shirt_size = value.profile.shirt_size,
          customer_info.transportation = value.profile.transportation
        customer_info.is_active = value.profile.is_active
        customer_info.url_avatar = value.profile.url_avatar
      }
      customer_list.push(customer_info);
    });
    state.attendees = customer_list;
  },
  // Speaker
  
  GET_SPEAKER_LIST: (state, payload) => {
    let speaker_data = payload.results != undefined ? payload.results : payload;
    let speaker_list = [];
    
    speaker_data.forEach(value => {
      let speaker_info = {
        id: value.id,
        username: value.username,
        firstname: value.first_name,
        lastname: value.last_name,
        lastlogin: value.lastlogin,
        email: value.email,
        age: value.profile.age,
        phone: value.profile.phone,
        gender: value.profile.gender,
        country_code: value.profile.country_code,
        groups: value.groups,
        privilege: value.privilege,
        code: value.profile.code,
        is_active: value.profile.is_active,
        checkin_status: value.checkin_status,
        url_avatar: value.profile.url_avatar,
        company_name: value.profile.company_name,
        company_position: value.profile.company_position
      };
      speaker_list.push(speaker_info);
    });
    state.speakers = speaker_list;
  },
  GET_EVENT_WITH_DATE: (state, payload) => {
    state.events = payload
  },
  GET_EVENT_SESSION: (state, payload) => {
    state.eventSessions = payload
  },
  GET_EVENT_SESSION_BY_ROLE: (state, payload) => {
    state.eventSessionsByRole = payload
  },
  GET_USER_DETAIL: (state, payload) => {
    state.userDetail = payload
  },
  GET_USER_IN_EVENT_LIST: (state, payload) => {
    let attendee_speaker_data = payload.results != undefined ? payload.results : payload;
    let attendee_speaker_list = [];
    attendee_speaker_data.forEach(value => {
      let attendee_speaker_info = {
        id: value.id,
        firstname: value.first_name,
        lastname: value.last_name,
        lastlogin: value.lastlogin,
        email: value.email,
        age: value.profile.age,
        phone: value.profile.phone,
        is_active: value.profile.is_active,
        url_avatar: value.profile.url_avatar,
        company_name: value.profile.company_name,
        company_position: value.profile.company_position,
        user_in_event: value.user_in_event,
        is_group: value.is_group,
        seats: value.seats
      };
      attendee_speaker_list.push(attendee_speaker_info);
    });
    // Paginate
    if(payload.page!=undefined){
      let page = {
        totalPage: payload.num_pages,
        currentPage: payload.page,
        pageSize: payload.page_size,
        perPage: payload.per_page
      }
      state.attendeesSpeakerList = { results: attendee_speaker_list, paginate: page};
    }else{
      state.attendeesSpeakerList = attendee_speaker_list;
    }
    
  },
  GET_USER_NOT_IN_EVENT: (state, payload) => {
    let user_not_in_event_data = payload.results;
    let user_not_in_event_list = [];
    
    user_not_in_event_data.forEach(value => {
      let user_not_in_event_info = {
        id: value.id,
        firstname: value.first_name,
        lastname: value.last_name,
        email: value.email,
        is_active: value.profile.is_active,
        url_avatar: value.profile.url_avatar,
        company_name: value.profile.company_name,
        company_position: value.profile.company_position,
        is_group: value.is_group
      };
      user_not_in_event_list.push(user_not_in_event_info);
    });
    // Paginate
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.userNotInEvent = { results: user_not_in_event_list, paginate: page };
  },
  GET_REASON_ABSENCE_DETAIL: (state, payload) => {
    state.reasonAbsence = payload
  },
  GET_TOKEN_MEDIA_SERVER: (state, payload) => {
    state.tokenMediaServer = payload
  },
  GET_ATTENDANCE_NUMBER: (state, payload) => {
    state.attendanceNumber = payload
  },
  GET_USER_LIST: (state, payload) => {
    let user_data = payload.results != undefined ? payload.results : payload;
    let user_list = [];
    
    user_data.forEach(value => {
      let user_info = {
        id: value.id,
        username: value.username,
        firstname: value.first_name,
        lastname: value.last_name,
        lastlogin: value.lastlogin,
        email: value.email,
        age: value.profile.age,
        phone: value.profile.phone,
        gender: value.profile.gender,
        country_code: value.profile.country_code,
        groups: value.groups,
        privilege: value.privilege,
        code: value.profile.code,
        is_active: value.profile.is_active,
        checkin_status: value.checkin_status,
        url_avatar: value.profile.url_avatar,
        company_name: value.profile.company_name,
        company_position: value.profile.company_position
      };
      user_list.push(user_info);
    });
    state.userList = user_list;
  },
  GET_CHAIRMAN_FOR_MEETING: (state, payload) => {
    state.chairman = payload
  },
  GET_BAND_GROUP_LIST: (state, payload) => {
    state.user_band_group = payload;
  },
  GET_USER_IN_BAND_GROUP: (state, payload) => {
    state.user_in_band_group_list = payload;
  },
  GET_BAND_GROUP_IN_EVENT: (state, payload) => {
    state.band_group_in_event_list = payload;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
