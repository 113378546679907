import common from "Api/index";

const state = {
    venueList: [],
    supportList: [],
    supportChat: []
}

const getters = {
    GET_VENUE_LIST_DATA: state => {
        return state.venueList
    },
    GET_SUPPORT_LIST_DATA: state => {
        return state.supportList
    },
    GET_SUPPORT_CHAT_DATA: state => {
        return state.supportChat
    },
}

const actions = {
    GET_VENUE_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/venues/",
                payload,
                function (res) {
                    context.commit("GET_VENUE_LIST", res.data);
                    resolve(res);
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    CREATE_SUPPORT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/support/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    UPDATE_SUPPORT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.putRequest(
                `api/v1/support/${payload.id}/`,
                payload,
                function (res) {
                    resolve(res.data)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_SUPPORT_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/support/",
                payload,
                function (res) {
                    context.commit("GET_SUPPORT_LIST", res.data);
                    resolve(res);
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    POST_SUPPORT_CHAT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/support/post_support_chat/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_SUPPORT_CHAT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/support/get_support_chat/",
                payload,
                function (res) {
                    context.commit("GET_SUPPORT_CHAT", res.data);
                    resolve(res);
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    CLOSE_REQUEST_SUPPORT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.putRequest(
                `api/v1/support/${payload.id}/close_request_support/`,
                payload,
                function (res) {
                    resolve(res.data)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
}

const mutations = {
    GET_VENUE_LIST: (state, payload) => {
        state.venueList = payload
    },
    GET_SUPPORT_LIST: (state, payload) => {
        state.supportList = payload
    },
    GET_SUPPORT_CHAT: (state, payload) => {
        state.supportChat = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}