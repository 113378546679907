<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import './style/index.scss';
html, body {
  font-family: 'Roboto Light', sans-serif;
}
#app {
  font-family: 'Roboto Light', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 50px;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0.3, $light-primary), color-stop(0.7, $primary));
    border-radius: 5px;
    background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
</style>
