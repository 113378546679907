// Bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
// Bootstrap vue
import 'bootstrap-vue/dist/bootstrap-vue.css';
// Font awesome
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
// Date time picker bootstrap
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
