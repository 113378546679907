<template>
  <div>
    <b-modal
      v-model="isShow"
      :title="$t('common.confirm')"
      centered
      id="modal-center"
    >
      <div class="container">
        <div class="row">
          <p>{{ $t(content) }} <span>{{ title }}?</span></p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right btn-submit ml-3"
            style="border: none;"
            @click="onConfirm()"
          >
            {{ $t('common.accept') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            style="background-color: #fff; color: #000; border: none;"
            @click="isShow = false"
          >
            {{ $t('common.close') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data: () => ({
    isShow: false,
    title: ''
  }),
  methods: {
    // On confirm
    onConfirm: function () {
      this.$emit('onConfirm')
      this.isShow = false
    },
    /**
     * On show modal confirm
     */
    onShowModalConfirm: function(title) {
      this.title = title
      this.isShow = true;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../style/color.scss";
.btn-submit {
    background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0.3, $light-primary), color-stop(0.7, $primary));
    border-radius: 5px;
}
</style>
