<template>
  <div ref="snackbar" class="snackbar" :class="{snackbarShow : snackBarData.isShow}">
    <div class="alert" :class="snackBarData.styleType.cls">
      <i class="icon fa" :class="snackBarData.styleType.icon"></i> {{$t(snackBarData.message)}}
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'lte-snack-bar',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      snackBarData: {
        id: "",
        isShow: false,
        timerFadeOut: null,
        message: "",
        styleType: {
          cls: "",
          icon: ""
        },
        timeLife: 0
      },
      styleObject: {
        danger: "alert-danger",
        info: "alert-info",
        warning: "alert-warning",
        success: "alert-success"
      },
      iconObject: {
        danger: "fa-ban",
        info: "fa-info",
        warning: "fa-warning",
        success: "fa-check"
      }
    }
  },
  methods: {
    create() {
      this.snackBarData.isShow = true;
      this.snackBarData.timerFadeOut = setTimeout(() => { this.delete(); }, 3000);
    },
    delete() {
      this.snackBarData.isShow = false;
      this.DELETE_TOAST(this.item);
    },
    getStyle(style) {
      let cls = this.styleObject[style];
      let icon = this.iconObject[style];
      return {
        cls: cls,
        icon: icon
      }
    },
    ...mapActions(["DELETE_TOAST"])
  },
  mounted() {
    this.snackBarData.id = this.item.id;
    this.snackBarData.message = this.item.message;
    this.snackBarData.styleType = this.getStyle(this.item.styleType);
    this.snackBarData.timeLife = this.item.timeLife;
    this.snackBarData.isShow = true;
    this.create();
  }
}

</script>

<style lang="scss" scoped>
.snackbar {
  visibility: hidden;
  min-width: 250px;
  //margin-left: -125px;
  //background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  position: fixed;
  z-index: 10;
  //left: 50%;
  bottom: 0;
  font-size: 17px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.snackbar > div {
  margin-bottom: 0px;
  padding: 5px;
}

.snackbarShow {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}


@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
</style>