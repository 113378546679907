<template>
    <div>
        <div v-if="DRAWER_CHANGE == true" class="sidenav sidenav_container">
            <div class="avatar" @click="goToUserProfile()" style="cursor: pointer; margin-top: 5px !important; padding-top: 0px !important;">
                <div class="row">
                    <div class="col-2 p-0" style="padding-left: 10px !important;">
                        <avatar
                            v-if="urlAvatar"
                            :src="urlAvatar"
                            :size="30"
                        ></avatar>
                        <avatar 
                            v-else
                            :username="fullName" 
                            backgroundColor="#ffffff" 
                            color="#0097A7" 
                            :size="30"
                        >
                        </avatar>
                    </div>
                    <div class="col-8 mt-1 p-0">
                        <span style="padding-left: 15px; display: inline-block; color: #ffffff; text-align: left;">{{ fullName }}</span>
                    </div>
                </div>
            </div>
            <div v-for="(link, index) in IN_A_MEETING ? customLinks : calendarLinks" :key="link + index">
                <div style="margin-top: 4px;" v-if="!link.isShow">
                    <router-link event="" v-if="link.isShowDialog" :to="'disable'" v-on:click.native="onShowDialogConfirm()" :class="routerPath == link.to ? 'active' : 'not-active'">
                        <img style="width: 19.5px; height: 19.5px; margin-top: 7px;" :src="require(`../../assets/sidebar_icon/${routerPath == link.to ? link.iconActive : link.icon}.svg`)">
                        <a class="link-text" style="padding-left: 15px;">{{ $t(link.text) }}</a>
                    </router-link>
                    <router-link event="" v-else-if="link.goToAdminPage" :to="'disable'" v-on:click.native="onShowModalConfirmGoToAdminPage()" :class="routerPath == link.to ? 'active' : 'not-active'">
                        <img style="width: 19.5px; height: 19.5px; margin-top: 7px;" :src="require(`../../assets/sidebar_icon/${routerPath == link.to ? link.iconActive : link.icon}.svg`)">
                        <a class="link-text" style="padding-left: 15px;">{{ $t(link.text) }}</a>
                    </router-link>
                    <router-link v-else :to="link.to" :class="routerPath == link.to ? 'active' : 'not-active'">
                        <img style="width: 19.5px; height: 19.5px; margin-top: 7px;" :src="require(`../../assets/sidebar_icon/${routerPath == link.to ? link.iconActive : link.icon}.svg`)">
                        <a class="link-text" style="padding-left: 15px;">
                          <span>{{ $t(link.text) }}</span>
                          <span v-if="link.numberOfValue!=undefined" class="sidebar-number-class">{{link.numberOfValue}}</span>
                        </a>
                        
                    </router-link>
                </div>
                
            </div>
            <div v-if="!IN_A_MEETING">
                <div class="line-white"></div>
                <div class="logout" @click="onShowDialogConfirmLogOut()">
                    <img src="../../assets/sidebar_icon/logout.svg">
                    <a class="link-text" style="padding-left: 15px; display: inline;">{{ $t('login.logOut') }}</a>
                </div>
            </div>
        </div>
        <div v-else class="sidenav_reponsive sidenav_container">
            <div class="avatar-responsive" @click="goToUserProfile()" style="cursor: pointer; margin-top: 5px !important; padding-top: 0px !important;">
                <avatar
                    v-if="urlAvatar"
                    :src="urlAvatar"
                    :size="30"
                ></avatar>
                <avatar
                    v-else
                    :username="fullName" 
                    backgroundColor="#ffffff" 
                    color="#0097A7" 
                    :size="30"
                >
                </avatar>
            </div>
            <div v-for="(link, index) in IN_A_MEETING ? customLinks : calendarLinks" :key="link + index">
                <div style="margin-top: 4px;" class="child" :class="routerPath == link.to ? 'active' : 'not-active'" v-if="!link.isShow">
                    <router-link event="" v-if="link.isShowDialog" :to="'disable'" v-on:click.native="onShowDialogConfirm()">
                        <img style="width: 19.5px; height: 19.5px;" :src="require(`../../assets/sidebar_icon/${routerPath == link.to ? link.iconActive : link.icon}.svg`)">
                    </router-link>
                    <router-link event="" v-if="link.goToAdminPage" :to="'disable'" v-on:click.native="onShowModalConfirmGoToAdminPage()">
                        <img style="width: 19.5px; height: 19.5px;" :src="require(`../../assets/sidebar_icon/${routerPath == link.to ? link.iconActive : link.icon}.svg`)">
                    </router-link>
                    <router-link v-else :to="link.to">
                        <img style="width: 19.5px; height: 19.5px;" :src="require(`../../assets/sidebar_icon/${routerPath == link.to ? link.iconActive : link.icon}.svg`)">
                    </router-link>
                </div>
            </div>
            <div v-if="!IN_A_MEETING">
                <div class="line-white"></div>
                <div class="logout" @click="onShowDialogConfirmLogOut()">
                    <img src="../../assets/sidebar_icon/logout.svg">
                </div>
            </div>
        </div>
        <confirm-modal ref="confirmModal" :content="confirmModalContent" @onConfirm="onConfirm()"></confirm-modal>
        <confirm-modal ref="confirmModalLogOut" :content="confirmModalLogOutContent" @onConfirm="onLogout()"></confirm-modal>
        <!-- confirm go to admin page-->
        <confirm-modal ref="confirmGoToAdminPageModal" :content="confirmGoToAdminPageModalContent" @onConfirm="onConfirmGoToAdminPage()"></confirm-modal>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
import Avatar from 'vue-avatar'
import functionUtils from 'utils/functionUtils'
import ConfirmModal from 'Components/ConfirmModal'
import historyActionEnum from 'enum/historyLogEnum'

export default {
    components: {
        avatar: Avatar,
        confirmModal: ConfirmModal
    },
    data: () => ({
        linkIndex: 0,
        routerPath: '',
        links: [
            {
                to: '/attendance',
                text: 'sideBar.attendance',
                icon: 'qrcode',
                iconActive: 'qrcode',
                isShow: true
            },
            {
                to: '/my-cle-credits',
                text: 'sideBar.information',
                icon: 'home-regular',
                iconActive: 'Home'
            },
            // {
            //     to: '/schedule',
            //     text: 'sideBar.schedule',
            //     icon: 'calendar-alt',
            //     iconActive: 'calendar'
            // },
            // {
            //     to: '/documents',
            //     text: 'sideBar.document',
            //     icon: 'file-alt',
            //     iconActive: 'file'
            // },
            {
                to: '/documents',
                text: 'sideBar.document',
                icon: 'file-alt',
                iconActive: 'file'
            },
            {
                to: '/stated',
                text: 'sideBar.stated',
                icon: 'hand-paper-alt',
                iconActive: 'hand-paper'
            },
            {
                to: '/ideal',
                text: 'sideBar.personalOpinion',
                icon: 'poll-alt',
                iconActive: 'poll'
            },
             {
                to: '/summary',
                text: 'sideBar.summary',
                icon: 'end-meeting',
                iconActive: 'end-meeting-alt'
            },
            {
                to: '/mission',
                text: 'sideBar.mission',
                icon: 'task-alt',
                iconActive: 'task'
            },
            {
                to: '/feedback',
                text: 'sideBar.feedback',
                icon: 'comments-alt',
                iconActive: 'comments'
            },
            {
                to: '/my-card',
                text: 'sideBar.myCode',
                icon: 'address-card-alt',
                iconActive: 'my-card'
            },
            {
                to: '/contact',
                text: 'sideBar.attendee',
                icon: 'user-friends-alt',
                iconActive: 'user-friends'
            },
            {
                to: '/guide',
                text: 'sideBar.callSupport',
                icon: 'question-circle-alt',
                iconActive: 'question-circle',
                isShow: process.env.VUE_APP_IS_FOR_DONGNAI.trim() === "true"
            },
            {
                to: '/notification',
                text: 'sideBar.notification',
                icon: 'bell-alt',
                iconActive: 'bell'
            },
            {
                to: '/history',
                text: 'sideBar.history',
                icon: 'history-solid',
                iconActive: 'history-solid'
            },
            {
                to: '',
                text: 'sideBar.goToAdminPage',
                icon: 'laptop_mac',
                iconActive: 'bell',
                isShow: process.env.VUE_APP_IS_FOR_DONGNAI === "false",
                goToAdminPage: true
            },
            {
                to: '/meeting',
                text: 'sideBar.chooseMeeting',
                icon: 'Exit',
                isShowDialog: true
            }
        ],
        calendarLinks: [
            // {
            //     to: '/calendar-day',
            //     text: 'calendar.calendarDay',
            //     icon: 'calendar-day',
            //     iconActive: 'my-card'
            // },
            {
                to: '/search',
                text: 'search.title',
                icon: 'search',
                iconActive: 'search'
            },
            {
                to: '/meeting-calendar',
                text: 'calendar.meetingCalendar',
                icon: 'calendar-month',
                iconActive: 'calendar-month'
            },
            // {
            //     to: '/calendar-week',
            //     text: 'calendar.calendarWeek',
            //     icon: 'calendar-week',
            //     iconActive: 'calendar-week'
            // },
            // {
            //     to: '/calendar-month',
            //     text: 'calendar.calendarMonth',
            //     icon: 'calendar-month',
            //     iconActive: 'calendar-month'
            // },
            // {
            //     to: '/calendar-year',
            //     text: 'calendar.calendarYear',
            //     icon: 'calendar-year',
            //     iconActive: 'calendar-year'
            // },
            {
                to: '/guide-calendar',
                text: 'sideBar.callSupport',
                icon: 'question-circle-alt',
                iconActive: 'question-circle'
            },
            {
                to: '/notification',
                text: 'sideBar.notification',
                icon: 'bell-alt',
                iconActive: 'bell'
            },
        ],
        fullName: '',
        userId: null,
        urlAvatar: null,
        customLinks: [],
        confirmModalContent: 'common.leaveMeeting',
        confirmModalLogOutContent: 'common.logOutMeeting',
        forICTDongnai: process.env.VUE_APP_IS_FOR_DONGNAI === "true",
        confirmGoToAdminPageModalContent: 'common.notificationGoToAdminPage'
    }),
    computed: {
        ...mapGetters([
          'DRAWER_CHANGE', 
          'IN_A_MEETING', 
          'GET_CURRENT_ACCOUNT', 
          "GET_NUMBER_VALUE_FOR_SIDEBAR_DATA", 
          "CALLBACK_API_ATTENDANCE"])
    },
    watch: {
        IN_A_MEETING(){
            if (this.IN_A_MEETING==true){
                let eventId=sessionStorage.getItem('event_id');
                if(eventId){
                    let filter = {
                    params: {
                        event: eventId ? eventId : null
                    }
                }
                this.SET_USER_DATA(filter)
                    .then(
                        function() {
                          this.getNumberValueForSifebar()
                        }.bind(this)
                    )
                    .catch(
                        function() {
                        this.onLogout()
                        }.bind(this)
                    );
                }
            }
        },
        GET_CURRENT_ACCOUNT: function () {
            this.userId = this.GET_CURRENT_ACCOUNT.id
            this.fullName = this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname;
            this.urlAvatar = this.GET_CURRENT_ACCOUNT.url_avatar
            if (this.IN_A_MEETING == true){
                if(this.isSecretaryRole() || this.isChairmanRole() || this.userId==1){
                  this.links[0].isShow = false
                }
            }
        },
        '$route' (to) {
            this.routerPath = to.path
            if (this.routerPath === '/meeting' && this.routerPath !== 'calendar-day') {
                this.$router.push({
                    name: 'calendar.meetingCalendar'
                })
                this.$store.state.app.inAMeeting = false
                sessionStorage.removeItem('event_id')
            }
            if (this.routerPath === '/feedback') {
              this.callbackApiFeedback()
            } else {
              clearInterval(this.$store.state.app.feedbackSetInterval)
              this.$store.state.app.feedbackSetInterval = null
            }
        },
        GET_NUMBER_VALUE_FOR_SIDEBAR_DATA: function() {
          let data = this.GET_NUMBER_VALUE_FOR_SIDEBAR_DATA
          this.customLinks = [...this.links]
          data.forEach(v => {
            let index = this.customLinks.findIndex(x=>x.text.includes(v.title))
            if (index !== -1){
              // this.customLinks[index].numberOfValue = null
              this.customLinks[index].numberOfValue = v.value
            }
          })
        },
        CALLBACK_API_ATTENDANCE: function() {
          this.getNumberValueForSifebar()
        }
    },
    methods: {
        isSecretaryRole: function () {
            return functionUtils.isSecretaryRole(this.GET_CURRENT_ACCOUNT)
        },
        isChairmanRole: function () {
            return functionUtils.isChairmanRole(this.GET_CURRENT_ACCOUNT)
        },
        /**
         * Go to user profile
         */
        goToUserProfile: function () {
            this.$router.push({
                name: 'userProfile.title',
                params: {
                    id: this.userId
                }
            })
        },
        /**
         * Set drawer
         */
        setDrawer: function () {
            this.$store.state.app.drawer = !this.$store.state.app.drawer
        },
        /**
         * On log out
         */
        onLogout: function () {
            let token = isCookieEnabled() ? getCookie('token') : null
            let data = {
              action_type: historyActionEnum.LOGOUT_MEETING,
              description: this.$t('historyLog.descriptionText.logoutMeeting')
            }
            this.createHistoryLog(data)
            this.LOGOUT(token).then(
                function () {
                    this.$router.push({
                        name: 'checkLogin'
                    })
                }.bind(this)
            ).catch(
                function () {
                    this.$router.push({
                        name: 'checkLogin'
                    })
                }.bind(this)
            )
        },
        /**
         * On responsive inverted
         */
        onResponsiveInverted: function () {
            if (window.innerWidth < 991) {
                this.$store.state.app.drawer = false
            } else {
                this.$store.state.app.drawer = true
            }
        },
        onShowDialogConfirm(){
            this.$refs.confirmModal.onShowModalConfirm()
        },
        onConfirm(){
            this.$router.push({
                name: 'calendar.meetingCalendar'
            })
            this.$store.state.app.inAMeeting = false
            // create history log
            let data = {
              action_type: historyActionEnum.LEAVE_MEETING,
              description: this.$t('historyLog.descriptionText.leaveMeeting'),
              event_id: sessionStorage.getItem('event_id')
            }
            this.createHistoryLog(data)
            sessionStorage.removeItem('event_id')
            document.title=this.projectTitle()
        },
        projectTitle: function() {
            const prefix_title = this.forICTDongnai ? "" : "[a' Meeting] - "
            let defaultTitle = `${prefix_title}${this.$t('common.appTitle')}`
            // if (event){
            //     return `${defaultTitle} - ${event.name}`
            // }
            return `${defaultTitle}`
        },
        onShowDialogConfirmLogOut(){
            this.$refs.confirmModalLogOut.onShowModalConfirm()
        },
        onShowModalConfirmGoToAdminPage: function () {
          this.$refs.confirmGoToAdminPageModal.onShowModalConfirm()
        },
        onConfirmGoToAdminPage: function(){
          let aminUlr = process.env.VUE_APP_ADMIN_URL;
          let url = aminUlr
          let urlData = functionUtils.removeDoubleSlashesFromUrl(url);
          window.open(urlData, "_blank");
        },
        createHistoryLog: function (data) {
          this.CREATE_HISTORY_LOG(data).then(
            function () {
            }.bind(this)
          )
        },
        callbackApiFeedback: function () {
          if (this.$store.state.app.feedbackSetInterval === null){
            this.$store.state.app.feedbackSetInterval = setInterval(() => {
              let value = this.$store.state.app.callbackApiFeedback
              this.$store.state.app.callbackApiFeedback = !value
            }, 5000)
          }
        },
        getNumberValueForSifebar: function() {
          let filter = {
            params: {
              eventId: sessionStorage.getItem('event_id')
            }
          }
          this.GET_NUMBER_VALUE_FOR_SIDEBAR(filter)
        },
        ...mapActions(['LOGOUT', 'SET_USER_DATA', 'CREATE_HISTORY_LOG', "GET_NUMBER_VALUE_FOR_SIDEBAR"])
    },
    mounted() {
        const isLogged = () => loadSessionFromCookie()
        const loadSessionFromCookie = () => {
            const token = isCookieEnabled() ? getCookie('token') : null
            if (token) {
                return true
            } else {
                return false
            }
        }
        if (!isLogged()) {
            this.$router.push({
                name: 'checkLogin'
            })
        } else {
            let eventId = sessionStorage.getItem('event_id')
            let filter = {
                params: {
                    event: eventId ? eventId : null
                }
            }
            this.SET_USER_DATA(filter)
                .then(
                    function() {
                    }.bind(this)
                )
                .catch(
                    function() {
                    this.onLogout()
                    }.bind(this)
                );
            if (eventId == null) {
                // this.$router.push({
                //     'name': 'calendar.meetingCalendar'
                // })
                let checkPathRoute = this.calendarLinks.findIndex(x => x.to == this.$route.path)
                if (checkPathRoute == -1){
                    this.$router.push({
                        'name': 'calendar.meetingCalendar'
                    })
                }
                this.$store.state.app.inAMeeting = false
            } else {
                this.$store.state.app.inAMeeting = true
            }
            this.getNumberValueForSifebar()
        }
        this.routerPath = this.$route.path
        // Responsive inverted
        this.onResponsiveInverted()
        window.addEventListener('resize', this.onResponsiveInverted)
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.onResponsiveInverted)
    },
}
</script>
<style lang="scss" scoped>
@import '../../style/color.scss';
.avatar-responsive {
    padding: 10px; 
    margin-bottom: 15px; 
    margin-right: 5px; 
    padding-left: 5px; 
    padding-top: 15px !important;
}
.avatar {
    margin-top: 5px; 
    text-align: left; 
    padding: 10px; 
    margin-bottom: 20px; 
    padding-left: 10px; 
    padding-top: 10px !important;
}
.line-white {
    background-color: #ffffff; height: 1px; width: 100%; margin-top: 5px;
}
.sidenav_container {
  height: 100%;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(linear,left bottom,left top,color-stop(0.3, $light-primary),color-stop(0.7, $primary));
  overflow-x: hidden;
  padding-top: 5px;
}
.sidenav_reponsive {
    width: 40px;
}
.sidenav {
    width: 260px;
}
.sidenav_reponsive .child {
    width: 100%;
    text-align: left;
    padding: 11px;
    padding-top: 12px;
    text-decoration: none;
    font-size: 15px;
    color: #ffffff;
    display: inline-flex;
    font-weight: 200;
    padding-left: 10px;
    padding-right: 10px;
}
.sidenav a {
    width: 100%;
    text-align: left;
    padding: 6px;
    text-decoration: none;
    font-size: 15px;
    color: #ffffff;
    display: inline-flex;
    font-weight: 200;
    padding-left: 10px;
    padding-right: 10px;
}
.active {
  background-color: $active-primary;
}
.not-active {
    background-color: transparent;
}
.sidenav a:hover {
  color: #ffffff;
  background-color: $active-primary;
}
.sidenav_reponsive .child:hover {
    background-color: $active-primary;
}
.logout {
    margin-top: 5px; 
    text-align: left; 
    padding: 10px; 
    cursor: pointer
}
.logout:hover {
    background-color: $active-primary;
}
/* a.router-link-active {
    background-color: #0097A7;
} */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

.sidebar-number-class {
  // position: absolute;
  // top: 83px;
  // right: 11px;
  align-self: center;
  color: white;
  font-weight: 300;
  position: relative;
  top: -10px;
  padding: 0px 4px;;
  border: 1px solid;
  border-radius: 5px;
  margin-left: 5px;
  font-size: 12px;
}
</style>