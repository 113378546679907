const TemplateType = {
    Email: 0,
    Notification: 1,
    SMS: 2
}
const ChannelsActionCodeEnum = {
    UserInEvent: 'user_in_event',
    UserNotInEvent: 'user_not_in_event',
    Sentiment: 'sentiment',
    Opinion: 'opinion',
    Conclusion: 'conclusion',
    Task: 'task'
}
const ActionEnum = {
    UpdateAttendanceStatus: 'update_attendance_status',
    AddUserInEvent: 'add_user_in_event',
    Sentiment: 'sentiment',
    Opinion: 'opinion',
    Conclusion: 'conclusion',
    Task: 'task'
}

const notificationTabType = {
    General: 0,
    ToDoList: 1
}

const notificationStatusEnum = {
    Unread: 0,
    Read: 1,
    Delete: 2
}
const privilegeEnum = {
    Support: 5
}

const NotificationType = {
    Attendance: 1,
    AddNewMission: 2,
    DocumentApproval: 3,
    ConfirmSeats: 4,
    ReasonAbsence: 5,
    DocumentNeedApproval: 6,
    DocumentReject: 7,
    ChangeMissionStatus: 8,
    ConfirmReportMeeting: 9,
    UpdateDocumentReviewer: 10,
    DocumentUploader: 11,
    Opinion: 12,
    Stated: 13,
    confirmStated: 14,
    exchangeDocument: 15,
    rejectStated: 16,
    exchangeDocumentApproval: 17,
    exchangeDocumentReject: 18
}

const NotificationTypeCode = [
    {value: 'attendance', text: 'notification.btnAction.attendance'},
    {value: 'confirm_seats', text: 'notification.btnAction.confirm_seats'},
    {value: 'document', text: 'notification.btnAction.document'},
    {value: 'mission', text: 'notification.btnAction.mission'},
    {value: 'opinion', text: 'notification.btnAction.opinion'},
    {value: 'stated', text: 'notification.btnAction.stated'}
]

const JoinMeetingEnum = {
  LEAVE_MEETING: 0,
  JOIN_MEETING: 1
}
export default {
    TemplateType, 
    ChannelsActionCodeEnum, 
    ActionEnum, 
    notificationTabType, 
    notificationStatusEnum, 
    privilegeEnum, 
    NotificationType, 
    NotificationTypeCode,
    JoinMeetingEnum
}
