/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */
// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
// Routes
import paths from './paths'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
// Use vue router
Vue.use(Router)
// Create a new router
const router = new Router({
  base: process.env.VUE_APP_BASE_PATH,
  mode: 'history',
  fallback: false,
  routes: paths
})
const isLogged = () => loadSessionFromCookie()
const loadSessionFromCookie = () => {
  const token = isCookieEnabled() ? getCookie('token') : null
  if (token) {
    return true
  } else {
    return false
  }
}
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!isLogged()) {
      next()
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
