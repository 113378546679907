<template>
    <div :class="DRAWER_CHANGE ? 'view-container' : 'view-container-responsive'">
        <transition
            name="fade"
            mode="out-in"
        >
            <router-view></router-view>
        </transition>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['DRAWER_CHANGE'])
    },
}
</script>
<style scoped>
.view-container {
    margin-left: 260px;
}
.view-container-responsive {
    margin-left: 40px;
}
</style>