export default {
  'common': {
    'appTitle': 'Phòng Họp Không Giấy',
    'close': 'Đóng',
    'somethingWentWrong': 'Có lỗi xảy ra!',
    'networkError': 'Lỗi Kết Nối Mạng',
    'accept': 'Chấp nhận',
    'confirm': 'Xác nhận',
    'today': 'Hôm nay',
    'send': 'Gửi',
    'success': 'Thành công',
    'delete': 'Xoá',
    'save': 'Lưu',
    'leaveMeeting': 'Bạn có muốn rời khỏi hội thảo không',
    'logOutMeeting': 'Bạn có muốn đăng xuất khỏi phòng họp',
    'inputFieldNull': 'Vui lòng nhập/chọn',
    'exportExcel': 'Xuất excel',
    'importExcel': 'Nhập excel',
    'eventHasEnded': 'Sự kiện đã kết thúc',
    'approvedWaiting': 'Bạn đã đăng ký, vui lòng chờ phê duyệt',
    'nonumber': 'STT',
    'action': 'Công cụ',
    'addNew': 'Thêm mới',
    "not_found_email_info": "Không tìm thấy thông tin email",
    'search': 'Tìm kiếm',
    'notificationGoToAdminPage': 'Bạn có muốn truy cập trang quản trị',
    'update': 'Cập nhật'
  },
  'login': {
    'title': 'Đăng nhập',
    'username': 'Tài khoản',
    'password': 'Mật khẩu',
    'loginInvalid': 'Tài Khoản Hoặc Mật Khẩu Không Đúng',
    'logOut': 'Đăng xuất',
    'emailAlready': 'Email đã đăng ký vui lòng nhập mật khẩu',
    'enterPassword': 'Nhập mật khẩu',
    'clickOnLogin': 'Người dùng nhấn đăng nhập xem như đã đồng ý',
    'termsOfUse': 'Điều khoản sử dụng',
    'forgotPassword': 'Quên mật khẩu?',
    'clickHere': 'Nhấn vào đây',
    'toGetBack': 'để lấy lại'
  },
  'register': {
    'title': 'Đăng ký',
    'emailIsNotRegistered': 'Email chưa ghi nhận trong hệ thống, vui lòng nhập thông tin để đăng ký',
    'setAccessPassword': 'Đặt mật khẩu truy cập',
    'password': 'Mật khẩu',
    'fullName': 'Họ và tên',
    'firstName': 'Tên',
    'phone': 'Số điện thoại',
    'position': 'Chức vụ',
    'companyName': 'Đơn vị',
    "password_reset_done_title": "Mail hướng dẫn đổi mật khẩu đã gửi",
    "password_reset_done_c1": "Chúng tôi đã gửi mail cho bạn hướng dẫn cách lấy mật khẩu mới, nếu bạn đã có tài khoản đăng ký với email được điền. Bạn sẽ nhận được email trong ít phút.",
    "password_reset_done_c2": "Nếu bạn không nhận được email, vui lòng đảm bảo bạn đã điền đúng thông tin email được dùng để đăng ký, và kiểm tra cả spam folder.",
    "password_reset_set_pass_title": "Điền mật khẩu mới",
    "password_reset_set_pass_content": "Vui lòng điền mật khẩu mới hai lần để xác nhận bạn điền chính xác.",
    "password_reset_set_pass_btn1": "Mật khẩu mới",
    "password_reset_set_pass_btn2": "Xác nhận mật khẩu",
    "password_reset_set_pass_btn3": "Đổi mật khẩu",
    "uppercase": "Chữ Hoa",
    "lowercase": "Chữ Thường",
    "digit": "Số",
    'checkNewPasswordAndOldPassword': 'Mật khẩu mới và mật khẩu xác nhận mới không khớp.',
    'validatePasswordError': 'Vui lòng nhập đúng định dạng mật khẩu',
    'callBackLogin': 'Trở lại trang đăng nhập',
    "emailTitleC1": "Mail đã được kích hoạt ",
    "emailTitleC2": "Tài khoản của bạn đã được kích hoạt",
    "emailTitleC3": "Vui lòng kiểm tra mail và đăng nhập",
    "emailTitleC4": "Mail không thể kích hoạt",
    "emailTitleC5": "Tài khoản của bạn không thể kích hoạt",
  },
  'checkLogin': {
    'loginMessage': 'Nhập email hoặc số điện thoại để đăng nhập',
    'loginPlaceholder': 'email, tài khoản hoặc số điện thoại',
    'continue': 'Tiếp tục',
    'accountNoteExist': 'Tài khoản không tồn tại',
    'phoneNumberDuplicate': 'Số điện thoại bị trùng lặp',
    'notExistEmailInSystem': 'Email không tồn tại trong hệ thống, bạn có muốn đăng ký',
    'plsEnterAccount': 'Vui lòng nhập tài khoản để đăng nhập',
    'meetingRoomsWithoutPaper': 'Phòng họp không giấy',
    'ICTMeetingRoomsWithoutPaperC1': "Tỉnh ủy đồng nai",
    'ICTMeetingRoomsWithoutPaperC2': "Hệ thống gửi nhận văn bản"
  },
  'checkForm': {
    'invalidAccount': 'Thông tin tài khoản đăng nhập là bắt buộc',
    'invalidEmail': 'Email là bắt buộc',
    'invalidPassword': 'Mật khẩu là bắt buộc',
    'invalidFullName': 'Họ và tên là bắt buộc',
    'invalidFirstName': 'Tên là bắt buộc',
    'invalidPhone': 'Số điện thoại là bắt buộc',
    'invalidPosition': 'Chức vụ là bắt buộc',
    'invalidCompanyName': 'Tên đơn vị là bắt buộc',
    'emailIsNotValid': 'Định dạng email không chính xác',
  },
  'attendance': {
    'title': 'Điểm danh',
    'avatar': 'Ảnh đại diện',
    'name': 'Tên',
    'position': 'Chức vụ',
    'status': 'Trạng thái',
    'addAttendees':'Thêm người tham dự',
    'absencesNotice': 'Vắng mặt',
    'noAttendance': 'Chưa điểm danh',
    'search': 'Tìm kiếm',
    'attendedAttendance': 'Đã điểm danh',
    'joinMeeting': 'Vào phòng họp',
    'addToMeeting': 'Thêm vào phòng họp',
    'attendanceList': 'Danh sách tham dự',
    'emptyListMessage': 'Danh sách trống',
    'group':{
      'addGroup': 'Thêm nhóm người tham dự',
      'title': 'Nhóm',
      'groupDetail': 'Thông tin nhóm',
      'groupAddNew': 'Thêm mới nhóm',
      'groupID': 'Định danh nhóm',
      'groupName': 'Tên nhóm',
      'description': 'Mô tả',
      'numberOfPeople': 'Số lượng người',
      'userInGroup': 'Danh Sách Người Dùng Trong Nhóm',
      'notificationDeleteGroup': 'Bạn có chắc chắn muốn xóa nhóm này',
      'selectUser': 'Chọn người dùng',
      'userAlreadyExistsInTheGroup': "Người dùng đã tồn tại trong nhóm",
      "notificationRemoveUser": "Bạn có chắc chắn muốn xóa người dùng ra khỏi nhóm",
      'addNewUser': 'Thêm người dùng'
    },
    'confirmUserAttendance': 'Bạn đồng ý xác nhận điểm danh trong cuộc họp?',
    'numberUserJoinMeeting': 'Đang trong phòng họp'
  },
  'schedule': {
    'title': 'Lịch trình',
    'generalCalendar': 'Lịch chung',
    'favoriteCalendar': 'Lịch yêu thích'
  },
  'calendar': {
    'calendarDay': 'Lịch ngày',
    'calendarMonth': 'Lịch tháng',
    'calendarWeek': 'Lịch tuần',
    'calendarYear': 'Lịch năm',
    'meetingCalendar': 'Lịch họp',
    "noMeeting": "Không có sự kiện nào diễn ra",
    "startDate": 'Ngày bắt đầu'
  },
  'meeting': {
    'title': 'Cuộc họp',
    'noData': 'Chưa cập nhập thông tin hội thảo',
    'noDataFavorite': 'Chưa cập nhật thông tin hội thảo yêu thích',
    'confirmChooseMeeting': 'Bạn có muốn tham gia hội thảo',
    'chaired': 'Chủ trì',
    'attendanceList': 'Danh sách tham dự',
    'attendant': 'Có mặt',
    'absence': 'Vắng mặt',
    'noAttendance': 'Chưa điểm danh',
    'confirmRegisterMeeting': 'Bạn có muốn đăng ký tham gia hội thảo không?',
    'meetingEnd': 'Sự kiện đã kết thúc'
  },
  'scheduleMeeting': {
    'content': 'Nội dung hội thảo',
    'noDocument': 'Không có tài liệu',
    'documentTitle': 'Tài liệu'
  },
  'myCard': {
    'title': 'Mã QR',
    'myQrCode': 'Mã của tôi',
    'myCard': 'Name card của tôi',
    'changeInfo': 'Thay đổi thông tin',
    'functionChangNameCard': 'Chức năng trao đổi name card điện tử',
    'functionChangNameCardContent': 'Mã này chứa thông tin name-card của chính bạn, để đối tác của bạn quét mã này, thông tin sẽ được trao đổi với nhau'
  },
  'information': {
    'title': 'Thông tin chung cuộc họp',
    'meetingContent': 'Nội dung họp',
    'reasonAbsence': 'Báo vắng',
    'notInvitationInfo': 'Không có thông tin thư mời'
  },
  'document': {
    'title': 'Tài Liệu',
    'generalDocuments': 'Tài liệu chung',
    'personal': 'Cá nhân',
    'exchangeDocument': 'Tài liệu trao đổi',
    'public': 'Công khai',
    'private': 'Riêng tư',
    'note': {
      'title': 'Ghi chú tài liệu',
      'personalNotes': 'Ghi chú cá nhân',
      'invalidEnterNote': 'Vui lòng nhập ghi chú',
      'enterNote': 'Nhập ghi chú'
    },
    'security': 'Bảo mật',
    'time': 'Thời gian',
    'approver': 'Người phê duyệt',
    'notApprover': 'Người không duyệt',
    'downloadDocument': 'Tải tài liệu',
    'reportError': 'Báo lỗi',
    'noDocumentForThisContent': 'Chưa có tài liệu cho nội dung này',
    'addContent': 'Thêm nội dung',
    'enterNewContent': 'Nhập nội dung mới',
    'startTime': 'Thời gian bắt đầu',
    'endTime': 'Thời gian kết thúc',
    'addDocument': 'Thêm tài liệu',
    'content': 'Nội dung',
    'chooseContent': 'Chọn nội dung',
    'uploadNewDocument': 'Tải lên tài liệu mới',
    'documentTitle': 'Tiêu đề tài liệu',
    'enterTitle': 'Nhập tiêu đề',
    'needApproval': 'Cần phê duyệt',
    'yes': 'Có',
    'no': 'Không',
    'assignApprover': 'Gán người phê duyệt',
    'searchForApprover': 'Tìm kiếm người phê duyệt',
    'messageForApprover': 'Tin nhắn cho người phê duyệt',
    'enterMessage': 'Nhập tin nhắn',
    'searchForContent': 'Tìm kiếm nội dung',
    'cannotExceed10MB': 'Kích thước tệp không được vượt quá 10MB',
    'loading': 'Đang tải...',
    'role': {
      'title': 'Phân quyền',
      'reviewer': 'Đánh giá',
      'approver': 'Duyệt'
    },
    'documentNotApproved': 'Tài liệu không được duyệt',
    'messageForDocumentCreator': 'Lời nhắn cho người tạo tài liệu',
    'reasonNotApproved': 'Lý do không phê duyệt',
    'reject': 'Không duyệt',
    'uploader': 'Người tải',
    'message': 'Lời nhắn',
    'fieldRequire': {
      'contentAgenda': 'Nội dung là bắt buộc',
      'documentTitle': 'Tiêu đề tài liệu là bắt buộc',
      'documentMessage': 'Tin nhắn là bắt buộc',
      'chooseDocument': 'Vui lòng chọn tài liệu',
      'chooseApprover': 'Vui lòng chọn người để phê duyệt'
    },
    'documentUploader': 'Người tải tài liệu lên',
    'updateDocumentUploader' : 'Cập nhật người tải tài liệu lên',
    'currentDocumentUploader': 'Người được tải tài liệu hiện tại',
    'status': {
      'awaitingApproval': 'Chờ phê duyệt',
      'complete': 'Hoàn thành',
      'approved': 'Đã phê duyệt',
      'rejected': 'Không phê duyệt'
    },
    'date': 'Ngày',
    'signTheNumber': 'Ký số',
    'assignDocumentViewer': "Thêm người được xem tài liệu",
    'all': 'Tất cả',
    'updateDocumentReviewer': 'Cập nhật người được xem',
    'currentDocumentViewer': 'Người được xem tài liệu hiện tại',
    'titleConfirmDelete': 'Bạn có chắc chắn muốn xoá tài liệu',
    'plsUploadPdfFile': 'Chỉ hỗ trợ tập tin định dạng PDF cho tài liệu',
    'updateDigitalSigner': 'Cập nhật người ký số',
    'currentDigitalSigner': 'Người được ký số hiện tại',
    'addNewDigitalSigner': 'Thêm người được ký số',
    'btnPersonalToGeneral': 'Chuyển sang tài liệu chung',
    'chooseSession': 'Chọn lịch trình',
    'documentNote': 'Tài liệu ghi chú',
    'documentUpload': 'Tài liệu tải lên',
    'documentComment': 'Chú thích tài liệu',
    'transferDocument': 'Chuyển tài liệu',
    'deleteDocumentPersonalTitle': 'Bạn có chắc chắn muốn xoá tài liệu tải lên',
    'documentRecipient': 'Người nhận tài liệu',
    'selectDocumentRecipient': 'Chọn người nhận tài liệu',
    'approvalDocumentExchange': 'Bạn có chắc chắn muốn phê duyệt tài liệu',
    'documentName': 'Tên tài liệu',
    'sender': 'Người gửi',
    'documentExchange': 'Tài liệu trao đổi',
    'reasonRejectDocumentExchange': 'Lý do không phê duyệt',
    'exchangedDocument': 'Tài liệu được chia sẻ',
    'shareDocument': 'Chia sẻ tài liệu',
    'confirmDeleteEventSessionContent': 'Bạn có chắc chắn muốn xoá nội dung {0}'
  },
  'opinion': {
    'title': 'Biểu Quyết',
    'content': 'Nội dung biểu quyết',
    'security': 'Bảo mật',
    'startTime': 'Thời gian bắt đầu',
    'endTime': 'Thời hạn hiệu lực',
    'followers': 'Người theo dõi',
    'opinionCollector': 'Người lấy ý kiến',
    'plan': 'Phương án',
    'submitComments': 'Gửi ý kiến',
    'statistical': 'Thống kê biểu quyết',
    'another_opinion': 'Ý kiến khác',
    'haveSecurity': 'Có',
    'noSecurity': 'Không',
    'viewFull': 'Xem đầy đủ',
    'opinionCollectors': 'Danh sách người lấy ý kiến',
    'notificationAnswer': 'Ý kiến của bạn đã được ghi nhận',
    'yes/no': 'Có/Không',
    'addPlan': 'Thêm phương án',
    'createOpinion': 'Tạo biểu quyết',
    'addOpinionContent': 'Nhập nội dung biểu quyết',
    'createNewOpinion': 'Tạo biểu quyết mới',
    'opinionContent': 'Nội dung',
    'venueNotFound': 'Không tìm thấy địa điểm.',
    'errorNotAnswer': 'Vui lòng chọn/nhập câu trả lời',
    'chartEmptyData': [{
      'labels': 'Trống'
    }],
    'anotherOpinionContent': 'Ở đây nội dung ý kiến khác là:'
  },
  'sideBar': {
    'attendance': 'Điểm danh',
    'information': 'Thông tin chung',
    'schedule': 'Lịch trình',
    'document': 'Tài liệu',
    'discuss': 'Thảo luận',
    'personalOpinion': 'Ý kiến biểu quyết',
    'notification': 'Thông báo',
    'myCode': 'Mã của tôi',
    'contactList': 'Danh sách tham dự',
    'callSupport': 'Gọi hỗ trợ',
    'chooseMeeting': 'Rời cuộc họp',
    'mission': 'Nhiệm vụ',
    'summary': 'Kết luận cuộc họp',
    'stated': 'Xin phát biểu',
    'attendee': 'Sơ đồ vị trí',
    'feedback': 'Góp ý',
    'goToAdminPage': 'Trang quản trị',
    'history': 'Lịch sử'
  },
  'userProfile': {
    'title': 'Thông tin người dùng',
    'email': 'Email',
    'active': 'Đã xác nhận',
    'notActive': 'Chưa xác nhận',
    'changePassword': 'Đổi mật khẩu',
    'enterNewPassword': 'Nhập mật khẩu mới',
    'fullName': 'Họ và tên',
    'lastName': 'Họ và đệm',
    'firstName': 'Tên',
    'phone': 'Điện thoại',
    'companyName': 'Đơn vị',
    'companyPosition': 'Chức vụ',
    'signature': 'Chữ ký',
    'numSignature': 'Dạng ký số',
    'uploadSignatureFile': 'Tải file chữ ký',
    'methodNumSignature': 'Chọn hình thức ký số',
    'allowSms': 'Cho phép SMS',
    'allowEmail': 'Cho phép Email',
    'update': 'Cập nhật',
    'singOut': 'Đăng xuất',
    'sendConfirmMail': 'Gửi mail xác nhận',
    'sendConfirmSms': 'Gửi sms xác nhận',
    'fileMustBeImage': 'Tập tin phải là hình ảnh'
  },
  'reasonAbsence': {
    'title': 'Lý do vắng mặt',
    'sendReasonAbsence': 'Gửi báo vắng'
  },
  'mission': {
    'title': "Nhiệm vụ",
    'assigner': 'Người giao việc',
    'jobStatus': 'Trạng thái',
    'startTime': 'Bắt đầu',
    'time': 'Thời gian',
    'document': 'Tài Liệu',
    'pause': 'Tạm hoãn',
    'done': 'Hoàn thành',
    'attach': 'Đính kèm tài liệu',
    'addNewMission': 'Tạo nhiệm vụ mới',
    'assignPersonCompany': 'Gán cá nhân/đơn vị',
    'missionTitle': 'Tiêu đề nhiệm vụ',
    'discussInfo': 'Thông tin nhắc nhở',
    'endTime': 'Thời hạn',
    'selectDocument': 'Chọn Tài liệu',
    'uploadDocument': 'Tải lên tài liệu mới',
    'sendJobAssign': 'Gửi nhiệm vụ',
    'conclusionForMission': 'Kết luận liên quan',
    'selectPerson': 'Chọn cá nhân/đơn vị',
    'summary': 'Kết Luận',
    'receiver': 'Người tiếp nhận',
    'received': 'Đã nhận',
    'reopen': 'Mở lại',
    'notificationChangeStatus': 'Bạn có muốn chuyển nhiệm vụ sang trạng thái'
  },
  'summary': {
    'title': 'Kết luận',
    'annunciator': 'Người báo cáo',
    'status': 'Trạng thái',
    'reportTime': 'Thời gian báo cáo',
    'assignTask': 'Nhiệm vụ được gán',
    'yes': 'Có',
    'no': 'Không',
    'done': 'Hoàn thành',
    'public': 'Công bố',
    'waitingUpdate': 'Chờ cập nhật',
    'downDocument': 'Tải tài liệu',
    'sendEmail': 'Gửi email',
    'addMission': 'Thêm nhiệm vụ',
    'addNew': 'Tạo kết luận',
    'conclusionContent': 'Nội dung kết luận',
    'conclusionStatus': 'Trạng thái',
    'conclusionAssigner': 'Gán cho cá nhân',
    'conclusionEndTime': 'Thời hạn',
    'conclusionSelectDocument': 'Chọn Tài liệu',
    'uploadNewDocument': 'Tải lên tài liệu mới',
    'saveConclusion': 'Lưu kết luận',
    'cancelConclusion': 'Bỏ qua',
    'document': 'Tài liệu',
    'editSummary': 'Sửa nội dung',
    'deleteSummary': 'Xóa kết luận',
    'viewDocument': 'Xem nội dung',
    'confirmDeleteSummary': 'Bạn có chắc chắn muốn xóa kết luận này',
    'returnPreviousPage': 'Về trang trước',
    'otherDocument': 'Tài liệu khác',
    'exportConclusions': 'Xuất kết luận',
    'confirmSendEmailForTask': 'Bạn chắc chắn muốn gửi email tới những người được giao nhiệm vụ',
    'confirm': 'Xác nhận',
    'close': 'Đóng',
    'addDocument': 'Thêm tài liệu',
    'uploadDocument': 'Tải lên tài liệu',
    'summaryInfo': 'Thông tin kết luận'
  },
  'compareDate': {
    'startNotBiggerEndTime': 'Thời gian bắt đầu không được lớn hơn thời gian kết thúc',
    'startNotSmallerCurrentTime': 'Thời gian bắt đầu không được nhỏ hơn thời gian hiện tại'
  },
  'stated': {
    'title': 'Xin phát biểu',
    'enterContentRegisterSpeak': 'Vui lòng nhập nội dung đăng ký phát biểu',
    'enterContentSpeak': 'Nhập nội dung phát biểu',
    'contentSpeak': 'Nội dung phát biểu',
    'editSpeak': 'Sửa ý kiến',
    'cancelRegister': 'Huỷ đăng ký',
    'sendRegister': 'Gửi đăng ký',
    'status': 'Trạng thái',
    'timeSpeak': 'Thời gian phát biểu',
    'noSpeak': 'Thứ tự phát biểu',
    'speaker': 'Người phát biểu',
    'speakStatus': {
      'speaked': 'Đã phát biểu',
      'unspeak': 'Chưa phát biểu'
    },
    'requireContentSpeak': 'Nội dung phát biểu là bắt buộc',
    'statusType': {
      'done': 'Đã phát biểu',
      'waiting': 'Chưa phát biểu',
      'closed': 'Kết thúc',
      'aborted': 'Không cho phép',
      'cancelled': 'Huỷ đăng ký',
      'doing': 'Đang phát biểu',
      'criticize': 'Phản biện'
    },
    'speechPermission': 'Cho phép phát biểu',
    'speechNotAllowed': 'Không cho phép',
    'close': 'Kết thúc',
    'criticize': 'Phản biện',
    'update': 'Cập nhật đăng ký',
    'cancel': 'Huỷ đăng ký',
    'hours': 'Giờ',
    'minutes': 'Phút',
    'hour': 'Giờ',
    'minute': 'Phút',
    'seconds': 'Giây',
    'seat': 'Vị trí',
    'addUserStated': 'Chỉ định người phát biểu',
    'enterContentCriticism': 'Vui lòng nhập nội dung phản biện',
    'inputContentCriticism': 'Nhập nội dung phản biện',
    'criticismTitle': 'Phản biện ý kiến phát biểu',
    'btnCriticism': 'Đăng ký phản biện',
    'btnCancelCriticism': 'Hủy đăng ký phản biện',
    'notFoundUserStated': 'Không có người phát biểu'
  },
  'event': {
    'ongoingEvents': 'Những sự kiện đang diễn ra vào'
  },
  'support': {
    'title': 'Gọi hỗ trợ',
    'guideCalendarSupport': 'Gọi hỗ trợ',
    'contentSupport': 'Nội dung cần hỗ trợ',
    'enterContentSupport': 'Nhập nội dung cần hỗ trợ',
    'chooseMeeting': 'Chọn phòng họp',
    'sendRequest': 'Gửi yêu cầu',
    'location': 'Vị trí',
    'time': 'Thời gian',
    'status': 'Trạng thái',
    'received': 'Đã nhận',
    'editContent': 'Sửa nội dụng',
    'completed': 'Đã hoàn thành',
    'processing': 'Đang thực hiện',
    'supportContentChat': 'Nhập nội dung trả lời',
    'thanksMessages': 'Cảm ơn!',
    'selectMeeting': 'Chọn cuộc họp',
    'addNewSupport': 'Thêm mới hỗ trợ',
    'selectGroup': 'Chọn nhóm',
    'closeRequest': 'Đóng yêu cầu'
  },
  'attendeeList': {
    'addUserInSeats': 'Thêm người dùng',
    'addUserToPosition': 'Thêm người dùng vào vị trí',
    'chairMain': 'Người chủ trì',
    'userExistsTable': 'Người dùng đã có vị trí ngồi trong phòng họp!',
    'numberSeats': 'Vị trí ghế không hợp lệ',
    'position': 'VỊ TRÍ',
    'delegates': 'TỔ ĐẠI BIỂU',
    'delegate': 'ĐẠI BIỂU',
    'no': 'STT',
    'errorRoomInfo': 'Không tìm thấy thông tin phòng họp'
  },
  'notification':{
    'document':{
      'needApproval': 'TB: có tài liệu {0} cho hội thảo {1} cần phê duyệt.',
      'approval': 'TB: tài liệu {0} đã được phê duyệt bởi {1}',
      'reject': 'TB: tài liệu {0} đã được từ chối bởi {1}'
    },
    'mission':{
      'changeStatus': 'TB: nhiệm vụ {0} đã {1} bởi {2}',
      'createMission': 'TB: bạn được giao nhiệm vụ {0} trong {1}, cần hoàn thành trước {2}',
      'missionStatus': {
        'done': 'hoàn thành',
        'pause': 'tạm hoãn',
        'reopen': 'mở lại'
      }
    },
    'attendance':{
      'attendanceNotification': 'TB: xác nhận bạn {0} đã điểm danh',
      'reasonNotification': 'TB: xác nhận bạn {0} đã báo vắng với lý do {1}'
    },
    'meetingRoom':{
      'confirmSeats': 'TB: xác nhận bạn xếp vị trí {0} trong cuộc họp {1} tại {2}'
    },
    'summary':{
      'reportMeeting': 'TB: biên bản cuộc họp {0} đã được cập nhập, vui lòng truy cập hệ thống để xem lại và xác nhận'
    },
    'title': 'Thông báo',
    'thingToDo': 'Việc cần làm',
    'allNotification': 'Tất cả thông báo',
    'goToDocs': 'Đến tài liệu',
    'doNotHaveNewNotification': 'Bạn không có thông báo mới',
    'notificationTitle': 'Tiêu đề',
    'notificationSubTitle': 'Chủ đề',
    'notificationContent': 'Nội dung',
    'deleteNotificationContent': 'Bạn có chắc chắn muốn xóa thông báo này',
    'btnAction': {
      'attendance': 'Đến điểm danh',
      'confirm_seats': 'Sơ Đồ vị trí',
      'document': 'Đến tài liệu',
      'mission': 'Đến nhiệm vụ',
      'opinion': 'Đến biểu quyết',
      'stated': 'Đến xin phát biểu'
    }
  },
  'search': {
    'title': 'Tìm kiếm cuộc họp',
    'period': 'Khoảng thời gian',
    'searchTitle': 'Nhập tiêu đề cuộc họp',
    'buttonSearch': 'Tìm kiếm',
    'emptyList': 'Không tìm thấy kết quả'
  },
  "paginator": {
    "showing": "Đang hiển thị",
    "of": "trên",
    "records": "kết quả"
  },
  'feedback': {
    'feedbackContent': 'Góp ý cuộc họp'
  },
  'historyLog': {
    'descriptionText': {
      'loginMeeting': 'Đăng nhập phòng họp không giấy',
      'logoutMeeting': 'Đăng xuất phòng họp không giấy',
      'joinMeeting': 'Tham gia cuộc họp - {{event_name}}',
      'leaveMeeting': 'Rời khỏi cuộc họp - {{event_name}}'
    },
    'userAction': 'Người thực hiện',
    'action': 'Hành động',
    'description': 'Mô tả',
    'actionList': {
      'loginMeeting': 'Đăng nhập',
      'logoutMeeting': 'Đăng xuất',
      'joinMeeting': 'Tham gia phòng họp',
      'leaveMeeting': 'Rời khỏi phòng họp'
    },
    'dateCreated': 'Thời gian'
  }
}