/**
 * Global Components
 */
import SideBar from './components/cores/SideBar.vue'
import View from './components/cores/View.vue'
import Toolbar from './components/cores/Toolbar.vue'
import LoadingBar from './components/LoadingBar.vue'
import SnackBar from './components/SnackBar'
import Pagination from './components/cores/Pagination.vue'

const GlobalComponents = {
  install (Vue) {
    Vue.component('sideBar', SideBar)
    Vue.component('coreView', View)
    Vue.component('toolbar', Toolbar)
    Vue.component('loadingBar', LoadingBar)
    Vue.component('snackBar', SnackBar)
    Vue.component('pagination', Pagination)
  }
}

export default GlobalComponents
