import common from "Api";

// State
const state = {
  feedback: []
}

// Getters
const getters = {
  GET_FEEDBACK_LIST_DATA: state => {
    return state.feedback
  }
}
// Actions
const actions = {
  CREATE_FEEDBACK: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.postRequest(
        "api/v1/feedback/",
        payload,
        function (res) {
          resolve(res.data)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_FEEDBACK_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      common.getRequest(
        "api/v1/feedback/",
        payload,
        function (res) {
          context.commit('GET_FEEDBACK_LIST', res.data)
          resolve(res.data)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// Mutations
const mutations = {
  GET_FEEDBACK_LIST: (state, payload) => {
    state.feedback = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
