const Layout = () => import('Views/Layout/ContentLayout')
// const DashBoard = () => import('Views/Dashboard')
const AuthenticationLayout = () => import('Views/Users/View')
const Login = () => import('Views/Users/Login')
const PageNotFound = () => import('Views/PageNotFound')
// const Schedule = () => import('Views/Schedule/Schedule')
const Meeting = () => import('Views/Meeting/Meeting')
const myCode = () => import('Views/Users/MyCode')
const CalendarDay = () => import('Views/Calendar/CalendarDay')
const CalendarWeek = () => import('Views/Calendar/CalendarWeek')
const CalendarMonth = () => import('Views/Calendar/CalendarMonth')
const CalendarYear = () => import('Views/Calendar/CalendarYear')
const MeetingCalendar = () => import('Views/Calendar/MeetingCalendar')
const Information = () => import('Views/Information/Information')
// const Documents = () => import('Views/Documents/Documents')
const Attendance = () => import('Views/Attendance/AttendanceList')
const Register = () => import('Views/Users/Register')
const CheckLogin = () => import('Views/Users/CheckLogin')
const Opinion = () => import('Views/Opinion/Opinion')
const UserProfile = () => import('Views/Users/UserProfile')
const Mission = () => import('Views/Mission/Mission')
const MeetingResources = () => import('Views/Meeting/MeetingResources')
const Summary = () => import('Views/Summary/Summary')
// const ComingSoon = () => import('Views/ComingSoon')
const Stated = () => import('Views/Stated/Stated')
const Support = () => import('Views/Support/Support')
const AttendeeList = () => import('Views/Attendee/AttendeeList')
const PasswordResetDone = () => import('Views/Users/PasswordResetDone')
const PasswordResetSetPassword = () => import('Views/Users/PasswordResetSetPassword')
const Search = () => import('Views/Search/Search')
const Notification = () => import('Views/Notification/Notification')
const EmailActivated = () => import('Views/Users/EmailActivated')
const Feedback = () => import('Views/Feedback/Feedback')
const HistoryLog = () => import('Views/History/HistoryLog')
export default [
    {
      path: '/',
      component: Layout,
      redirect: '/my-cle-credits',
      children: [
        {
          path: '/attendance',
          name: 'attendance.title',
          component: Attendance,
          meta: { requiresAuth: true }
        },
        // {
        //   path: '/schedule',
        //   name: 'schedule.title',
        //   component: Schedule,
        //   meta: { requiresAuth: true }
        // },
        {
          path: '/calendar-day',
          name: 'calendar.calendarDay',
          component: CalendarDay,
          meta: { requiresAuth: true }
        },
        {
          path: '/calendar-week',
          name: 'calendar.calendarWeek',
          component: CalendarWeek,
          meta: { requiresAuth: true }
        },
        {
          path: '/calendar-month',
          name: 'calendar.calendarMonth',
          component: CalendarMonth,
          meta: { requiresAuth: true }
        },
        {
          path: '/calendar-year',
          name: 'calendar.calendarYear',
          component: CalendarYear,
          meta: { requiresAuth: true }
        },
        {
          path: '/meeting-calendar',
          name: 'calendar.meetingCalendar',
          component: MeetingCalendar,
          meta: { requiresAuth: true }
        },
        {
          path: '/meeting',
          name: 'sideBar.chooseMeeting',
          component: Meeting,
          meta: { requiresAuth: true }
        },
        {
          path: '/my-card',
          name: 'myCard.title',
          component: myCode,
          meta: { requiresAuth: true }
        },
        {
          path: '/my-cle-credits',
          name: 'information.title',
          component: Information,
          meta: { requiresAuth: true }
        },
        // {
        //   path: '/documents',
        //   name: 'document.title',
        //   component: Documents,
        //   meta: { requiresAuth: true }
        // },
        {
          path: '/ideal',
          name: 'opinion.title',
          component: Opinion,
          meta: { requiresAuth: true }
        },
        {
          path: 'user-profile/:id',
          name: 'userProfile.title',
          component: UserProfile,
          meta: { requiresAuth: true }
        },
        {
          path: '/mission',
          name: 'mission.title',
          component: Mission,
          meta: { requiresAuth: true }
        },
        {
          path: 'documents',
          name: 'document.title',
          component: MeetingResources,
          meta: { requiresAuth: true }
        },
        {
          path: 'summary',
          name: 'summary.title',
          component: Summary,
          meta: { requiresAuth: true }
        },
        {
          path: 'contact',
          name: 'sideBar.attendee',
          component: AttendeeList
        },
        {
          path: 'guide',
          name: 'support.title',
          component: Support
        },
        {
          path: 'stated',
          name: 'sideBar.stated',
          component: Stated
        },
        {
          path: 'guide-calendar',
          name: 'support.guideCalendarSupport',
          component: Support
        },
        {
          path: '/search',
          name: 'search.title',
          component: Search
        },
        {
          path: 'notification',
          name: 'notification.title',
          component: Notification,
          meta: { requiresAuth: true }
        },
        {
          path: 'feedback',
          name: 'sideBar.feedback',
          component: Feedback,
          meta: { requiresAuth: true }
        },
        {
          path: 'history',
          name: 'sideBar.history',
          component: HistoryLog,
          meta: { requiresAuth: true }
        }
      ]
    },
    {
      path: '/autehntication',
      component: AuthenticationLayout,
      name: 'authentication',
      children: [
        {
          path: '/login',
          component: Login,
          name: 'login'
        },
        {
          path: '/check-login',
          component: CheckLogin,
          name: 'checkLogin'
        },
        {
          path: '/register',
          component: Register,
          name: 'register'
        },
        {
          path: '*',
          component: PageNotFound,
          name: 'Page not found'
        },
        {
          path: '/reset-password',
          component: PasswordResetDone,
          name: 'reset-password'
        },
        {
          path: "/account/reset/:uidb64/:token",
          component: PasswordResetSetPassword,
          name: "reset-password-set-password"
        },
        {
          path: "/account/email_activated/:uidb64/:token",
          component: EmailActivated,
          name: "EmailActivated"
        }
      ]
    }
]