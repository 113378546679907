import moment from 'moment'

const STATIC_FORMAT_DATE = 'DD/MM/YYYY'
const STATIC_FORMAT_TIME_DATE = 'HH:mm DD/MM/YYYY'
const STATIC_FORMAT_DATE_SQL = 'YYYY-MM-DD'
const STATIC_FORMAT_DATE_TIME_SQL = 'YYYY-MM-DD HH:mm'
const STATIC_FORMAT_DATE_TIME_ZONE = 'YYYY-MM-DD HH:mm:ss.SSSZZZ'
const STATIC_FORMAT_DATE_TIME_ZONE_T = 'YYYY-MM-DDTHH:mm:ss.sssZ'

/**
 * Format date
 * @param {*} date
 * @param {*} fromFormat
 * @param {*} toFormat
 */
const formatDate = function (date, fromFormat, toFormat) {
  if (date === '') {
    return ''
  }
  return moment(date, fromFormat).format(toFormat)
}

/**
 * Convert millisecond to hours, minutes and seconds
 * @param {*} millisecond 
 */
const millisToHoursMinutesAndSeconds = function (millisecond) {
  var hours = Math.floor((millisecond / (1000 * 60 * 60)) % 24);
  var minutes = Math.floor(millisecond / 60000)%60;
  var seconds = ((millisecond % 60000) / 1000).toFixed(0);
  return {
    hours,
    minutes,
    seconds
  }
}

/**
 * Compare two date
 * @param {*} date1 
 * @param {*} date2 
 */
const compareDate = function (date1, date2) {
  if (date1 > date2) {
    return 1
  } else if (date1 < date2) {
    return -1
  } else {
    return 0
  }
}

export default {
  // Static variable
  STATIC_FORMAT_DATE,
  STATIC_FORMAT_DATE_SQL,
  STATIC_FORMAT_DATE_TIME_ZONE,
  STATIC_FORMAT_DATE_TIME_ZONE_T,
  STATIC_FORMAT_TIME_DATE,
  STATIC_FORMAT_DATE_TIME_SQL,
  // Function
  formatDate,
  compareDate,
  millisToHoursMinutesAndSeconds
}
