var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dropdown"},[_c('a',{attrs:{"id":"dLabel","role":"button","data-toggle":"dropdown","data-target":"#"}},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("../assets/icons/bell-alt.svg"),"alt":""}}),_c('span',{class:[
          'meeting-font-10px',
          _vm.notificationGeneralList.length + _vm.workList.length > 10 ? 'badge-2-number' : 'badge' ]},[_vm._v(_vm._s(_vm.notificationGeneralList.length + _vm.workList.length))])]),_c('div',{staticClass:"dropdown-menu notifications meeting-font-medium",attrs:{"role":"menu","aria-labelledby":"dLabel","id":"dropdown-menu"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row notification-header"},[_c('div',{staticClass:"pr-0"},[_c('div',{class:[
                'tab-document',
                _vm.tabType == _vm.notificationTabType.General
                  ? 'notification-tab-select'
                  : '' ],on:{"click":function($event){return _vm.onClickNotificationTab(_vm.notificationTabType.General)}}},[_c('span',{staticClass:"font-size-18"},[_vm._v(_vm._s(_vm.$t("notification.title")))])]),_c('div',{class:[
                'tab-document',
                _vm.tabType == _vm.notificationTabType.ToDoList
                  ? 'notification-tab-select'
                  : '' ],on:{"click":function($event){return _vm.onClickNotificationTab(_vm.notificationTabType.ToDoList)}}},[_c('span',{staticClass:"font-size-18"},[_vm._v(_vm._s(_vm.$t("notification.thingToDo")))])])]),_c('div',{staticClass:"float-right",staticStyle:{"position":"absolute","align-self":"center","margin-right":"14px","right":"0px","top":"8px"}},[_c('button',{staticClass:"btn p-0 btn-action-notification text-white meeting-font-medium",staticStyle:{"background":"#26ba50","border":"1px solid #26ba50"},attrs:{"type":"button"},on:{"click":_vm.onClickViewAllNotification}},[_vm._v(" "+_vm._s(_vm.$t("notification.allNotification"))+" ")])])]),(_vm.tabType == _vm.notificationTabType.General)?_c('div',{staticClass:"row notification-body",staticStyle:{"max-height":"300px","overflow":"auto"}},_vm._l((_vm.notificationGeneralList),function(item,index){return _c('div',{key:item + index,staticClass:"col-md-12 notification-body-item p-3",staticStyle:{"display":"flex"},on:{"click":function($event){return _vm.onClickViewNotification(item)}}},[_c('span',{staticClass:"btn-notification-clear",attrs:{"type":"button"},on:{"click":function($event){return _vm.onClickDeleteNotification(item)}}},[_c('img',{attrs:{"src":require("../assets/icons/abort-dark.svg"),"alt":""}})]),_c('div',{staticClass:"col-sm-2 p-0 notification-icon",staticStyle:{"align-self":"center"}},[_c('i',{class:item.icon_class,staticStyle:{"width":"18px","height":"24px","color":"#006AD4"}})]),_c('div',{staticClass:"col-sm-7 p-0 pr-2 notification-content"},[_c('p',{class:item.status == _vm.notificationStatusEnum.Unread ||
                  item.mark_as_unread
                    ? 'font-weight-bold'
                    : ''},[_vm._v(" "+_vm._s(item.title != null ? _vm.spliceString(item.title, 55) : null)+" ")]),_c('p',[_vm._v(_vm._s(item.sub_title != null ? _vm.spliceString(item.sub_title, 55) : null)+" ")]),_c('p',[_vm._v(_vm._s(item.content != null ? _vm.spliceString(item.content, 55) : null))])]),(item.btnActionTitle!=null)?_c('div',{staticClass:"col-sm-3 p-0 notification-action"},[_c('button',{staticClass:"btn p-0 btn-action-notification-item text-white meeting-font-medium",staticStyle:{"background":"#26ba50","border":"1px solid #26ba50"},attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.$t(item.btnActionTitle))+" ")])]):_vm._e()])}),0):_vm._e(),(_vm.tabType == _vm.notificationTabType.ToDoList)?_c('div',{staticClass:"row notification-body",staticStyle:{"max-height":"300px","overflow":"auto"}},_vm._l((_vm.workList),function(item,index){return _c('div',{key:item + index,staticClass:"col-md-12 notification-body-item p-3",staticStyle:{"display":"flex"},on:{"click":function($event){return _vm.onClickViewNotification(item)}}},[_c('span',{staticClass:"btn-notification-clear",attrs:{"type":"button"},on:{"click":function($event){return _vm.onClickDeleteNotification(item)}}},[_c('img',{attrs:{"src":require("../assets/icons/abort-dark.svg"),"alt":""}})]),_c('div',{staticClass:"col-sm-2 p-0 notification-icon",staticStyle:{"align-self":"center"}},[_c('i',{class:item.icon_class,staticStyle:{"width":"18px","height":"24px","color":"#006AD4"}})]),_c('div',{staticClass:"col-sm-7 p-0 pr-2 notification-content"},[_c('p',{class:item.status == _vm.notificationStatusEnum.Unread ||
                  item.mark_as_unread
                    ? 'font-weight-bold'
                    : ''},[_vm._v(" "+_vm._s(item.title != null ? _vm.spliceString(item.title, 55) : null)+" ")]),_c('p',[_vm._v(_vm._s(item.sub_title != null ? _vm.spliceString(item.sub_title, 55) : null)+" ")]),_c('p',[_vm._v(_vm._s(item.content != null ? _vm.spliceString(item.content, 55) : null))])]),(item.btnActionTitle!=null)?_c('div',{staticClass:"col-sm-3 p-0 notification-action"},[_c('button',{staticClass:"btn p-0 btn-action-notification-item text-white meeting-font-medium",staticStyle:{"background":"#26ba50","border":"1px solid #26ba50"},attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.$t(item.btnActionTitle))+" ")])]):_vm._e()])}),0):_vm._e(),(
            (_vm.tabType == _vm.notificationTabType.General &&
              _vm.notificationGeneralList.length == 0) ||
            (_vm.tabType == _vm.notificationTabType.ToDoList && _vm.workList.length == 0)
          )?_c('div',{staticClass:"row p-4 text-center"},[_c('div',{staticClass:"w-100"},[_c('span',[_vm._v(_vm._s(_vm.$t("notification.doNotHaveNewNotification")))])])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }