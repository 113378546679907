const NETWORK_ERROR = 'Network Error'
const GenderList = [
  {
    text: 'userProfile.genderType.male',
    value: 1
  },
  {
    text: 'userProfile.genderType.female',
    value: 2
  },
  {
    text: 'userProfile.genderType.other',
    value: 3
  }
]
const DocumentRole = [
  {
    text: 'document.role.reviewer',
    value: 2
  },
  {
    text: 'document.role.approver',
    value: 1
  }
]
const DocumentTypeList = [
  {
    text: 'Pdf',
    value: 1
  },
  {
    text: 'Video',
    value: 2
  },
  {
    text: 'Doc',
    value: 3
  }
]
const SummaryStatus = [
  {value: 0, text: 'summary.done'},
  {value: 1, text: 'summary.waitingUpdate'}
]
const MissionStatus = [
  {value: 0, text: 'mission.received'},
  {value: 1, text: 'mission.done'},
  {value: 2, text: 'mission.pause'}
]
const SupportStatus = [
  {value: 0, text: 'support.received'},
  {value: 1, text: 'support.processing'},
  {value: 2, text: 'support.completed'}
]
const EVENT_SESSION_SUMMARY_TITLE = 'Tồng Kết/Biên Bản'

const HistoryLogAction = [
  {value: 3, text: 'historyLog.actionList.loginMeeting'},
  {value: 5, text: 'historyLog.actionList.logoutMeeting'},
  {value: 7, text: 'historyLog.actionList.joinMeeting'},
  {value: 9, text: 'historyLog.actionList.leaveMeeting'}
]
export default {
  NETWORK_ERROR,
  GenderList,
  DocumentTypeList,
  DocumentRole,
  SummaryStatus,
  MissionStatus,
  SupportStatus,
  EVENT_SESSION_SUMMARY_TITLE,
  HistoryLogAction
}
