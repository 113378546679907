import common from "Api";

const state = {
    todolist: [],
    allNotification: []
}

const actions = {
    SEND_NOTIFICATION_IN_MEETING: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/meeting/pushnotification/",
                payload,
                function (res) {
                    resolve(res.data)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    SEND_SMS_IN_MEETING: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                `api/v1/sms/send_sms/`,
                payload,
                function (res) {
                    resolve(res);
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_NOTIFICATION_FOR_USER: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/notification_for_user/",
                payload,
                function (res) {
                    context.commit("GET_NOTIFICATION_FOR_USER", res.data);
                    resolve(res);
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    UPDATE_NOTIFICATION_FOR_USER_STATUS: (context, payload) => {
        return new Promise((resolve, reject) => {
          common.putRequest(
            `api/v1/notification_for_user/${payload.id}/`,
            payload,
            function (res) {
              resolve(res);
            },
            function (error) {
              reject(error)
            }
          )
        })
    },
    DELETE_NOTIFICATION_FOR_USER: (context, payload) => {
        return new Promise((resolve, reject) => {
          common.deleteRequest(
            `api/v1/notification_for_user/${payload.id}/`,
            {},
            function (res) {
              resolve(res)
            },
            function (error) {
              reject(error)
            }
          )
        })
      },
    GET_ALL_NOTIFICATION_FOR_USER_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/notification_for_user/get_all_notification/",
                payload,
                function (res) {
                    context.commit("GET_ALL_NOTIFICATION_FOR_USER_LIST", res.data);
                    resolve(res);
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
}

const mutations = {
    GET_NOTIFICATION_FOR_USER: (state, payload) => {
        let notification_data = payload.results != undefined ? payload.results : payload;
        let notification_list = []
        notification_data.forEach(value => {
            notification_list.push(value)
        })
        if(payload.page!=undefined){
            let page = {
                totalPage: payload.num_pages,
                currentPage: payload.page,
                pageSize: payload.page_size,
                perPage: payload.per_page
            }
            state.todolist = { results: notification_list, paginate: page};
        }else{
            state.todolist = payload
        }
    },
    GET_ALL_NOTIFICATION_FOR_USER_LIST: (state, payload) => {
        let notification_data = payload.results != undefined ? payload.results : payload;
        let notification_list = []
        notification_data.forEach(value => {
            notification_list.push(value)
        })
        if(payload.page!=undefined){
            let page = {
                totalPage: payload.num_pages,
                currentPage: payload.page,
                pageSize: payload.page_size,
                perPage: payload.per_page,
                total: payload.count
            }
            state.allNotification = { results: notification_list, paginate: page};
        }else{
            state.allNotification = payload
        }
    },
}

const getters = {
    GET_NOTIFICATION_FOR_USER_DATA: state => {
        return state.todolist
    },
    GET_ALL_NOTIFICATION_FOR_USER_LIST_DATA: state => {
        return state.allNotification
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}