<template>
  <div class="select2-container select2-container-multi full-width">
    <multiselect
      v-if="multipleValue"
      v-model="selected"
      :placeholder="placeHolder"
      :label="labelText"
      :track-by="trackByText"
      :options="optionData"
      :multiple="multipleValue"
      :option-height="104"
      :custom-label="customLabel"
      :close-on-select="closeOnSelect"
      @input="onSelect"
      :show-labels="false"
      :hideSelected="true"
    >
      <template slot="singleLabel" slot-scope="props">
        <!-- <img class="option__image" :src="props.option.img" alt="No Man’s Sky" /> -->
        <div style="display: flex; width: 30px; height: 30px; font-size: 14px">
          <div class="col-sm-1">
            <avatar
              :username="props.option.url_avatar"
              backgroundColor="#e5e5e5"
              color="#0097A7"
              :size="30"
            ></avatar>
          </div>
          <div class="col-sm-11 option__desc">
            <!-- <span class="option__desc"> -->
            <span class="option__title">{{ props.option.full_name }}</span>
            <!-- </span> -->
          </div>
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <!-- <img class="option__image" :src="props.option.img" alt="No Man’s Sky" /> -->
        <div style="display:flex; font-size: 14px">
          <div class="" style="align-self: center; width: 30px; height: 30px">
            <div v-if="props.option.is_group"
              style="width: 30px; height: 30px; background-color: #e5e5e5; border-radius: 50%;"
            >
              <i class="fas fa-users" style="align-self: center;
                position: relative;
                left: 23%;
                margin-right: -19%;
                transform: translate(-18%, -10%);
                top: 22%;
                font-size: 20px;
                color: rgb(0, 106, 212);">
              </i>
            </div>
            <div
              v-else
            >
              <avatar
                v-if="props.option.url_avatar"
                :src="props.option.url_avatar"
                backgroundColor="#e5e5e5"
                color="#0097A7"
                :size="30"
              ></avatar>
            
              <avatar
                v-else
                :username="props.option.full_name"
                backgroundColor="#e5e5e5"
                color="#0097A7"
                :size="30"
              ></avatar>
            </div>
          </div>
          <div class="col-sm-11 option__desc">
            <span class="option__title" 
              :style="props.option.is_group ? 'font-weight: bold;' : ''"
            >
              {{ props.option.full_name }}</span>
            <span v-if="props.option.email" class="font-italic">{{ $t('userProfile.email') }}: {{ props.option.email }}</span>
          </div>
        </div>
      </template>
    </multiselect>
    <!--  -->
    <multiselect 
      v-else
      v-model="selected" 
      :placeholder="placeHolder" 
      :label="labelText" 
      :track-by="trackByText" 
      :options="optionData" 
      :option-height="204" 
      :custom-label="customLabel" 
      :close-on-select="closeOnSelect"
      @input="onSelect"
      :show-labels="false">
      <template slot="singleLabel" slot-scope="props">
        <div style="display: flex; font-size: 14px">
          <div class="col-sm-1 option__image p-0">
            <avatar
              v-if="props.option.url_avatar"
              :src="props.option.url_avatar"
              backgroundColor="#e5e5e5"
              color="#0097A7"
              :size="30"
            ></avatar>
            <avatar
              v-else
              :username="props.option.full_name"
              backgroundColor="#e5e5e5"
              color="#0097A7"
              :size="30"
            ></avatar>
          </div>
          <div class="col-sm-11 pl-1 option__desc">
            <!-- <span class="option__desc"> -->
            <span class="option__title">{{ props.option.full_name }}</span>
            <!-- </span> -->
          </div>
        </div>
      </template>

      <template slot="option" slot-scope="props">
        <div style="display:flex; font-size: 14px">
          <div class="" style="align-self: center; width: 30px; height: 30px">
            <div
              v-if="props.option.url_avatar"
              style="width: 30px; height: 30px; background-color: #e5e5e5; border-radius: 50%;"
            >
              <avatar
                :src="props.option.url_avatar"
                backgroundColor="#e5e5e5"
                color="#0097A7"
                :size="30"
              ></avatar>
            </div>
            <div v-else>
              <avatar
                :username="props.option.full_name"
                backgroundColor="#e5e5e5"
                color="#0097A7"
                :size="30"
              ></avatar>
            </div>
          </div>
          <div class="col-sm-11 option__desc">
            <span class="option__title">{{ props.option.full_name }}</span>
            <span v-if="props.option.email" class="font-italic">{{ $t('userProfile.email') }}: {{ props.option.email }}</span>
          </div>
        </div>
    </template>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Avatar from "vue-avatar";
export default {
  components: {
    Multiselect,
    avatar: Avatar
  },
  props: {
    optionData: {
      type: Array,
      default() {
        return {
          id: 1
        };
      }
    },
    customLabel: {
      type: Function,
      default() {
        return {
          //   text: "",
          //   id: 1
        };
      }
    },
    labelText: {
      type: String,
      default() {
        return "";
      }
    },
    trackByText: {
      type: String,
      default() {
        return "";
      }
    },
    placeHolder: {
      type: String,
      default() {
        return "";
      }
    },
    multipleValue: {
      type: Boolean,
      default() {
        return true;
      }
    },
    closeOnSelect: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data: () => ({
    selected: null
  }),
  methods: {
    // customLabel({ title }) {
    //   return `${title}`;
    // },
    onSelect() {
      this.$emit("select", this.selected);
    },
    clearAll () {
      this.selected = []
    }
  }
};
</script>
<style scoped>
img {
  width: 100px;
}

.option__desc {
  /* width: 75%; */
  float: right;
  display: flex;
  flex-direction: column;
  /* margin: 0 0 0 10px; */
  align-self: center;
}
/* .circular--portrait {
  position: relative;
  max-width: 52px;
  max-height: 52px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.circular--portrait img {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
} */
</style>