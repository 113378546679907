<template>
  <div
    :class="this.$store.state.app.drawer ? 'header' : 'header-reponsive'"
    style="border-bottom: 1px solid #e5e5e5; background-color: #ffffff"
  >
    <div>
      <div class="drawer-icon">
        <menu-icon @click="setDrawer()"></menu-icon>
      </div>
    </div>
    <div>
      <div class="title" v-if="!isDocumentMenu">
        <h3 class="font-size-18" style="margin: 0;">{{ $t(title) }}</h3>
      </div>
      <div v-if="isDocumentMenu">
        <div
          :class="[
            'tab-document',
            DOCUMENT_TAB_TYPE == documentGeneral ? 'document-border' : '',
          ]"
          @click="changeDocumentTabType(documentGeneral)"
        >
          <span class="font-size-18">{{
            $t("document.generalDocuments")
          }}</span>
        </div>
        <div
          :class="[
            'tab-document',
            DOCUMENT_TAB_TYPE == documentPersonal ? 'document-border' : '',
          ]"
          @click="changeDocumentTabType(documentPersonal)"
        >
          <span class="font-size-18">{{ $t("document.personal") }}</span>
        </div>
        <div
          v-if="isSecretaryRole() || isChairmanRole()"
          :class="[
            'tab-document',
            DOCUMENT_TAB_TYPE == documentExchange ? 'document-border' : '',
          ]"
          @click="changeDocumentTabType(documentExchange)"
        >
          <span class="font-size-18">{{ $t("document.exchangeDocument") }}</span>
        </div>
      </div>
      <div v-if="isSummaryMenu">
        <!-- Export document file -->
        <div class="tab-document ml-4" v-if="isSecretaryRole() || isChairmanRole()">
          <button
            class="btn meeting-font-large meeting-font-regular"
            style="padding: 2px 5px 2px 5px; border: 1px solid #3F51B5; border-radius: 5px; color: #3F51B5"
            @click="exportSummary(actionSummaryExport)"
          >
            <img
              src="../../assets/icons/upload-file.svg"
              style="padding-bottom: 5px;"
            />
            {{ $t("summary.exportConclusions") }}
          </button>
          <div class="ml-4" style="position: relative; display: inline-block;">
            <label for="file-upload" class="custom-file-upload mb-0">
              <i
                class="fas fa-file-upload"
                style="position: absolute; left: 5px; color: #3F51B5; top: 5px;"
              ></i>
              <span
                class="meeting-font-large"
                style="word-wrap: break-word; padding-left: 21px; color: #3F51B5; padding-right: 5px;"
              >
                {{
                  documentFileName ? documentFileName : "Tải lên biên bản họp"
                }}</span
              >
            </label>
            <input
              class="input-upload-file"
              v-on:change="onFileChange"
              id="file-upload"
              type="file"
              accept="application/pdf"
            />
          </div>
        </div>
      </div>
      <!-- Notification -->
      <div class="float-right notification-class">
        <notification-component></notification-component>
      </div>
      
      <!-- End notification -->
      <!-- number user join meeting -->
      <div
        v-if="isAttendance"
        class="number-user-join-meeting font-weight-bold"
      >
      <span>{{$t('attendance.numberUserJoinMeeting')}}: </span>
      <span class="text-nowrap">{{attendanceNumber.joinMeeting}}/{{attendanceNumber.total}}</span>
      </div>
      <!-- end number user join meeting -->
      <!--  -->
      <div v-if="isSeatsMapMenu && (isSecretaryRole() || isChairmanRole())" 
        class="float-right mr-2" style="padding-top: 2px;"
      >
        <upload-excel :on-success="handleSuccess" :before-upload="beforeUpload"/>
      </div>
      <!--  -->
      
    </div>
    <!-- <div style="position: fixed; right: 5px; top: 10px">
            <div class="language">
                <span @click="changeLanguage()" style="cursor: pointer !important; padding: 10px; color: white; font-size: small; text-transform: uppercase;">{{ locale }}</span>
            </div>
        </div> -->
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
    <summaryAssignSigner ref="summaryAssignSigner"></summaryAssignSigner>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DocumentTabType from "enum/documentTabType";
import ActionSummaryType from "enum/actionSummaryType";
import SummaryAssignSigner from 'Views/Summary/SummaryAssignSigner'
import functionUtils from 'utils/functionUtils'
import UploadExcel from "Components/UploadExcel";
import NotificationComponent from "Components/NotificationComponent"

export default {
  components: {
    summaryAssignSigner: SummaryAssignSigner,
    UploadExcel,
    'notification-component': NotificationComponent
  },
  data: () => ({
    title: "",
    locale: "vi",
    documentGeneral: DocumentTabType.GENERAL,
    documentPersonal: DocumentTabType.PERSONAL,
    documentExchange: DocumentTabType.DOCUMENT_EXCHANGE_APPROVAL,
    isDocumentMenu: false,
    isSummaryMenu: false,
    actionSummaryExport: ActionSummaryType.EXPORT,
    uploadReportSummary: ActionSummaryType.UPLOAD_REPORT,
    documentFileName: null,
    documentFileSize: null,
    isSeatsMapMenu: false,
    isAttendance: false,
    attendanceNumber: {
      total: 0,
      joinMeeting: 0
    },
    forICTDongnai: process.env.VUE_APP_IS_FOR_DONGNAI === "true"
  }),
  watch: {
    $route(to) {
      this.title = to.name;
      this.checkDocumentRouter(to.name);
    },
    EVENT_DETAIL_DATA: function() {
      document.title = this.projectTitle(this.EVENT_DETAIL_DATA);
    },
    GET_ATTENDANCE_NUMBER_DATA: function () {
      this.attendanceNumber.total = this.GET_ATTENDANCE_NUMBER_DATA.total
      this.attendanceNumber.joinMeeting = this.GET_ATTENDANCE_NUMBER_DATA.user_join_event
    }
  },
  computed: {
    ...mapGetters(["EVENT_DETAIL_DATA", "DOCUMENT_TAB_TYPE", "GET_SNACK_BAR", "GET_CURRENT_ACCOUNT", "GET_ATTENDANCE_NUMBER_DATA"]),
  },
  methods: {
    /**
     * Check has support role
     */
    isSecretaryRole: function () {
      return functionUtils.isSecretaryRole(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Check has chairman role
     */
    isChairmanRole: function () {
      return functionUtils.isChairmanRole(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * On file change
     */
    onFileChange: function(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.documentFileName = files[0].name;
      this.documentFileSize = files[0].size;
      this.getResultFile(files[0]);
    },
    /**
     * Get result file
     */
    getResultFile: function(file) {
      let vm = this;
      vm.documentFile = file;
      /**
       * Param 1: Document file
       * Param 2: Document file name
       * Param 3: Variable is update
       * Param 4: Current document selected
       */
      this.$refs.summaryAssignSigner.onShowModalConfirm(vm.documentFile, this.documentFileName, false, null)
    },
    /**
     * Export summary click
     */
    exportSummary: function(action) {
      this.$store.state.summary.actionSummaryType = action;
    },
    /**
     * Check document router name
     */
    checkDocumentRouter: function(routerName) {
      if (routerName == "document.title") {
        this.isDocumentMenu = true;
      } else {
        this.isDocumentMenu = false;
      }
      if (routerName == "summary.title") {
        this.isSummaryMenu = true;
      } else {
        this.isSummaryMenu = false;
      }
      if (routerName == "sideBar.attendee"){
        this.isSeatsMapMenu = true;
      }else{
        this.isSeatsMapMenu = false;
      }
      if (routerName == 'attendance.title') {
        this.isAttendance = true
      }else{
        this.isAttendance = false
      }
    },
    /**
     * Change document tab type
     */
    changeDocumentTabType: function(documentTabType) {
      this.$store.state.documentation.documentTabType = documentTabType;
    },
    /**
     * Change language
     */
    changeLanguage: function() {
      let locale = this.$store.state.language.selectedLocale;
      if (locale == "en") {
        this.$i18n.locale = "vi";
        this.locale = "vi";
      } else {
        this.$i18n.locale = "en";
        this.locale = "en";
      }
      this.$store.dispatch("changeLanguage", this.locale);
      if (sessionStorage.getItem("event_id")) {
        document.title = this.projectTitle(this.EVENT_DETAIL_DATA);
      } else {
        document.title = this.projectTitle();
      }
    },
    /**
     * Set drawer
     */
    setDrawer: function() {
      this.$store.state.app.drawer = !this.$store.state.app.drawer;
    },
    projectTitle: function(event) {
      const prefix_title = this.forICTDongnai ? "" : "[a' Meeting] - "
      let defaultTitle = `${prefix_title}${this.$t("common.appTitle")}`;
      if (event) {
        return `${defaultTitle} | ${event.name}`;
      }
      return `${defaultTitle}`;
    },
    handleSuccess: function({results, header}){
      this.$store.state.attendeelist.upload_file_data_results = results;
      this.$store.state.attendeelist.upload_file_data_headers = header;
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      return false;
    },
    callbackApiGetUserAttendance: function () {
      if (this.$store.state.app.attendanceSetInterval === null){
        this.$store.state.app.attendanceSetInterval = setInterval(() => {
          let value = this.$store.state.app.callbackApiAttendance
          this.$store.state.app.callbackApiAttendance = !value
        }, 20000)
      }
    },
    ...mapActions(["GET_EVENT_DETAIL", "ON_SHOW_TOAST"]),
  },
  mounted() {
    this.title = this.$route.name;
    let event_id = sessionStorage.getItem("event_id");
    let event = {
      params: {
        is_meeting: true
      },
      id: event_id
    }
    this.GET_EVENT_DETAIL(event);
    this.checkDocumentRouter(this.$route.name);
  },
  created () {
    this.callbackApiGetUserAttendance()
  }
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.language {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  border-radius: 5px;
}
.title {
  float: left;
  margin-left: 10px;
  margin-top: 1px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.header {
  width: 100%;
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0;
}
.header span svg {
  float: left !important;
  margin-left: 270px;
  width: 25px;
  height: 25px;
  margin-top: 10px;
}
.header-reponsive {
  width: 100%;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
}
.header-reponsive span svg {
  float: left !important;
  margin-left: 47px;
  width: 25px;
  height: 25px;
  margin-top: 10px;
}
.drawer-icon {
  cursor: pointer !important;
  color: $primary;
}
.tab-document {
  float: left;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.document-border {
  border-bottom: 2px solid #f2404d;
}
.font-size-18 {
  font-size: 18px !important;
}
.custom-file-upload {
  border: 1px solid #3f51b5;
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  padding-bottom: 4px;
}
.input-upload-file[type="file"] {
  display: none;
}
.notification-class{
  margin-right: 25px;
}
.number-user-join-meeting{
  float: right;
  margin-left: 10px;
  margin-top: 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #00bcd4;
}
</style>
