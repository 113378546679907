<template>
  <div>
    <!-- <img src="../assets/icons/bell-alt.svg" alt="" /> -->
    <div class="dropdown">
      <a id="dLabel" role="button" data-toggle="dropdown" data-target="#">
        <img
          src="../assets/icons/bell-alt.svg"
          alt=""
          style="cursor: pointer"
        />
        <span
          :class="[
            'meeting-font-10px',
            notificationGeneralList.length + workList.length > 10 ? 'badge-2-number' : 'badge',
          ]"
          >{{ notificationGeneralList.length + workList.length }}</span
        >
        <!-- <span
          :class="[
            'meeting-font-10px',
            notificationGeneralList.length > 10 ? 'badge-2-number' : 'badge',
          ]"
          :style="notificationGeneralList.length > 10 ? 'right:-14; ' : ''"
          >44</span
        > -->
      </a>

      <div
        class="dropdown-menu notifications meeting-font-medium"
        role="menu"
        aria-labelledby="dLabel"
        id="dropdown-menu"
      >
        <!-- header -->
        <div class="container">
          <div class="row notification-header">
            <div class="pr-0">
              <div
                :class="[
                  'tab-document',
                  tabType == notificationTabType.General
                    ? 'notification-tab-select'
                    : '',
                ]"
                @click="onClickNotificationTab(notificationTabType.General)"
              >
                <span class="font-size-18">{{ $t("notification.title") }}</span>
              </div>
              <div
                :class="[
                  'tab-document',
                  tabType == notificationTabType.ToDoList
                    ? 'notification-tab-select'
                    : '',
                ]"
                @click="onClickNotificationTab(notificationTabType.ToDoList)"
              >
                <span class="font-size-18">{{
                  $t("notification.thingToDo")
                }}</span>
              </div>
            </div>
            <div
              class="float-right"
              style="
                position: absolute;
                align-self: center;
                margin-right: 14px;
                right: 0px;
                top: 8px;
              "
            >
              <button
                class="btn p-0 btn-action-notification text-white meeting-font-medium"
                type="button"
                style="background: #26ba50; border: 1px solid #26ba50"
                @click="onClickViewAllNotification"
              >
                {{ $t("notification.allNotification") }}
              </button>
            </div>
          </div>

          <!-- Notification list content -->
          <div
            class="row notification-body"
            style="max-height: 300px; overflow: auto"
            v-if="tabType == notificationTabType.General"
          >
            <div
              class="col-md-12 notification-body-item p-3"
              style="display: flex"
              v-for="(item, index) in notificationGeneralList"
              :key="item + index"
              @click="onClickViewNotification(item)"
            >
              <span
                type="button"
                class="btn-notification-clear"
                @click="onClickDeleteNotification(item)"
                ><img src="../assets/icons/abort-dark.svg" alt=""
              /></span>
              <div
                class="col-sm-2 p-0 notification-icon"
                style="align-self: center"
              >
                <i :class="item.icon_class" style="width: 18px; height: 24px; color: #006AD4"></i>
              </div>
              <div class="col-sm-7 p-0 pr-2 notification-content">
                <!-- <p>{{}}</p> -->
                <p
                  :class="
                    item.status == notificationStatusEnum.Unread ||
                    item.mark_as_unread
                      ? 'font-weight-bold'
                      : ''
                  "
                >
                  {{ item.title != null ? spliceString(item.title, 55) : null }}
                </p>
                <p>{{ item.sub_title != null ? spliceString(item.sub_title, 55) : null}} </p>
                <p>{{ item.content != null ? spliceString(item.content, 55) : null}}</p>
              </div>
              <div class="col-sm-3 p-0 notification-action" v-if="item.btnActionTitle!=null">
                <button
                  class="btn p-0 btn-action-notification-item text-white meeting-font-medium"
                  type="button"
                  style="background: #26ba50; border: 1px solid #26ba50"
                >
                  {{ $t(item.btnActionTitle) }}
                </button>
              </div>
            </div>
          </div>
          <div 
            class="row notification-body"
            style="max-height: 300px; overflow: auto"
            v-if="tabType == notificationTabType.ToDoList"
          >
            <div
              class="col-md-12 notification-body-item p-3"
              style="display: flex"
              v-for="(item, index) in workList"
              :key="item + index"
              @click="onClickViewNotification(item)"
            >
              <span
                type="button"
                class="btn-notification-clear"
                @click="onClickDeleteNotification(item)"
                ><img src="../assets/icons/abort-dark.svg" alt=""
              /></span>
              <div
                class="col-sm-2 p-0 notification-icon"
                style="align-self: center"
              >
                <i :class="item.icon_class" style="width: 18px; height: 24px; color: #006AD4"></i>
              </div>
              <div class="col-sm-7 p-0 pr-2 notification-content">
                <!-- <p>{{}}</p> -->
                <p
                  :class="
                    item.status == notificationStatusEnum.Unread ||
                    item.mark_as_unread
                      ? 'font-weight-bold'
                      : ''
                  "
                >
                  {{ item.title != null ? spliceString(item.title, 55) : null}}
                </p>
                <p>{{ item.sub_title != null ? spliceString(item.sub_title, 55) : null}} </p>
                <p>{{ item.content != null ? spliceString(item.content, 55) : null}}</p>
              </div>
              <div class="col-sm-3 p-0 notification-action" v-if="item.btnActionTitle!=null">
                <button
                  class="btn p-0 btn-action-notification-item text-white meeting-font-medium"
                  type="button"
                  style="background: #26ba50; border: 1px solid #26ba50"
                >
                  {{ $t(item.btnActionTitle) }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="row p-4 text-center"
            v-if="
              (tabType == notificationTabType.General &&
                notificationGeneralList.length == 0) ||
              (tabType == notificationTabType.ToDoList && workList.length == 0)
            "
          >
            <div class="w-100">
              <span>{{ $t("notification.doNotHaveNewNotification") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Enum from "../enum/enum";
import $ from "jquery";
import functionUtils from "utils/functionUtils";
import privilege from "../enum/privilege"
export default {
  data: () => ({
    notificationGeneralList: [],
    workList: [],
    notificationTabType: Enum.notificationTabType,
    tabType: Enum.notificationTabType.General,
    notificationStatusEnum: Enum.notificationStatusEnum,
    workListType: [
      Enum.NotificationType.AddNewMission,
      Enum.NotificationType.DocumentNeedApproval
    ]
  }),
  watch: {
    GET_NOTIFICATION_FOR_USER_DATA: function () {
      // console.log(this.GET_NOTIFICATION_FOR_USER_DATA);
      this.notificationGeneralList = [];
      this.workList = [];
      this.GET_NOTIFICATION_FOR_USER_DATA.forEach((data, index) => {
        let nonumber = index + 1;
        let content = data.notification_data.body;
        let title = data.notification_data.title;
        let status = data.status;
        let mark_as_unread = data.mark_as_unread;
        let url = data.notification_type_data.url;
        let icon_class = data.notification_type_data.icon_class
        let notification_type_code = data.notification_type_data.code
        let sub_title = data.notification_data.sub_title;
        let item = {
          id: data.id,
          nonumber: nonumber,
          title: title,
          sub_title: sub_title,
          content: content,
          status: status,
          mark_as_unread: mark_as_unread,
          url: url,
          icon_class: icon_class,
          notification_type_code: notification_type_code,
          btnActionTitle: this.getNameButtonAction(notification_type_code),
          notification_type: data.notification_type,
          event_id: data.event_id
        };
        if (this.workListType.includes(data.notification_type)){
          this.workList.push(item)
        }else{
          this.notificationGeneralList.push(item);
        }
      });
    },
  },
  computed: {
    ...mapGetters(["GET_NOTIFICATION_FOR_USER_DATA"]),
  },
  methods: {
    spliceString: function (strText, len) {
      return functionUtils.spliceString(strText, len);
    },
    onClickNotificationTab: function (data) {
      this.tabType = data;
    },
    onClickViewNotification: function (data) {
      // create session event_id
      let filter = {
        params: {
          event_id: data.event_id,
        },
      };
      this.CHECK_USER_IN_EVENT(filter)
        .then(
          function(res){
            let privilegeData = res.data.privilege
            if(privilegeData.id != privilege.CHAIRMAN && 
              privilegeData.id != privilege.SECRETARY && data.notification_type == Enum.NotificationType.Attendance){
                // 
            }else{
              if(sessionStorage.getItem("event_id") != data.event_id){
                sessionStorage.setItem("event_id", data.event_id);
              }
              if(!this.$store.state.app.inAMeeting){
                this.$store.state.app.inAMeeting = true;
              }
              if(this.$route.name != data.url){
                this.$router.push({
                  name: data.url,
                });
              }
              
              
            }
          }.bind(this)
        )
        .catch(
          function(){

          }.bind(this)
        )
      this.UPDATE_NOTIFICATION_FOR_USER_STATUS(data)
        .then(
          function () {
            let filter = {
              params: {
                unread_params: true,
              },
            };
            this.GET_NOTIFICATION_FOR_USER(filter);
          }.bind(this)
        )
        .catch(
          function (error) {
            let message = error.response.data.message;
            this.ON_SHOW_TOAST({
              message: message,
              styleType: "danger",
            });
          }.bind(this)
        );
    },
    onClickDeleteNotification: function (data) {
      this.DELETE_NOTIFICATION_FOR_USER(data)
        .then(
          function () {
            let filter = {
              params: {
                unread_params: true,
              },
            };
            this.GET_NOTIFICATION_FOR_USER(filter);
          }.bind(this)
        )
        .catch(
          function (error) {
            let message = error.response.data.message;
            this.ON_SHOW_TOAST({
              message: message,
              styleType: "danger",
            });
          }.bind(this)
        );
    },
    onClickViewAllNotification: function () {
      if (this.$route.name != "notification.title") {
        this.$router.push({
          name: "notification.title",
        });
        $("#dropdown-menu").removeClass("show");
      } else {
        this.GET_NOTIFICATION_FOR_USER();
        $("#dropdown-menu").removeClass("show");
      }
    },
    getNameButtonAction: function(data){
      let index = Enum.NotificationTypeCode.findIndex(x=>x.value == data)
      let btnActionText = null;
      if(index != -1){
        btnActionText = Enum.NotificationTypeCode[index].text
      }
      return btnActionText
    },
    ...mapActions([
      "ON_SHOW_TOAST",
      "GET_NOTIFICATION_FOR_USER",
      "UPDATE_NOTIFICATION_FOR_USER_STATUS",
      "DELETE_NOTIFICATION_FOR_USER",
      "CHECK_USER_IN_EVENT"
    ]),
  },
  mounted() {
    $(
      ".dropdown-menu .notification-header, .dropdown-menu .container .notification-body"
    ).on("click", function (event) {
      event.stopPropagation();
    });
  },
  created() {
    let filter = {
      params: {
        unread_params: true,
      },
    };
    this.GET_NOTIFICATION_FOR_USER(filter);
  },
};
</script>
<style scoped>
.badge {
  position: absolute;
  top: 8px;
  right: -5px;
  padding: 3px 5px 3px 5px;
  border-radius: 50%;
  background-color: #f2404d;
  color: #ffffff;
}
.badge-2-number {
  position: absolute;
  top: 4px;
  right: -8px;
  padding: 2px 4px 2px 4px;
  border-radius: 50%;
  background-color: #f2404d;
  color: #ffffff;
}
.tab-document {
  float: left;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 6px;
  cursor: pointer;
}
.notification-tab-select {
  border-bottom: 2px solid #f2404d;
}
.notification-header {
  background-color: #eceff0;
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 0 5px;
}
.notification-body-item {
  border-bottom: 1px solid #cccccc;
}
.notification-icon {
  align-self: center;
  text-align: center;
}
.notification-content p {
  margin: 0;
}
.notification-action {
  align-self: center;
}
.btn-action-notification-item {
  padding: 2px 10px !important;
  border-radius: 9px;
}
.btn-action-notification {
  padding: 2px 15px !important;
  border-radius: 9px;
}
.btn-notification-clear {
  position: absolute;
  right: 17px;
  top: 1px;
  cursor: pointer;
}
.dropdown-menu {
  padding: 0;
  top: 12px !important;
  transform: translate3d(-356px, 30px, 0px) !important;
}
/* CSS used here will be applied after bootstrap.css */

.dropdown {
  display: inline-block;
  margin-left: 20px;
  padding: 10px;
}

.glyphicon-bell {
  font-size: 1.5rem;
}

.notifications {
  min-width: 420px;
}

.notifications-wrapper {
  overflow: auto;
  max-height: 250px;
}

.menu-title {
  color: #ff7788;
  font-size: 1.5rem;
  display: inline-block;
}

.glyphicon-circle-arrow-right {
  margin-left: 10px;
}

.dropdown-menu.divider {
  margin: 5px 0;
}

.item-title {
  font-size: 1.3rem;
  color: #000;
}

.notifications a.content {
  text-decoration: none;
  background: #ccc;
}

.notification-item {
  padding: 10px;
  /* margin: 5px; */
  background: #ccc;
  border-radius: 4px;
}
</style>