<template>
  <div class="overflow-auto mr-2" style="display: flex;">
    <div style="float: right">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        last-number
        @input="onPageChange"
      ></b-pagination>
    </div>
    <span
      v-if="total"
      class="ml-4 font-italic float-left"
      style="margin-top: 8px;"
    >
      {{$t('paginator.showing')}} {{start}} - {{end}} {{$t('paginator.of')}} {{total}} {{$t('paginator.records')}}
    </span>
  </div>
</template>
<script>
  export default {
    props: {
      rows: {
        type: Number,
        default: () => {
          return 1
        }
      },
      perPage: {
        type: Number,
        default: () => {
          return 1
        }
      },
      currentPage: {
        type: Number,
        default: () => {
          return 1
        }
      },
      total: {
        type: Number,
        default:() => {
          return null
        }
      },
    },
    data: () => ({
      size: 7
    }),
    methods: {
      onPageChange: function () {
        this.$emit('onPageChange', this.currentPage)
      }
    },
    computed: {
      start(){
        return 1 + (this.currentPage - 1) * this.size
      },
      end(){
        const maxPage = Math.floor((this.total - 1) / this.size) + 1
        if (this.currentPage < maxPage) {
          return this.size + (this.currentPage - 1) * this.size
        }
        return this.total
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
/deep/ .page-item .page-link {  
    color: #000000;
}
/deep/ .page-item.active .page-link {  
    background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0.3, $light-primary), color-stop(0.7, $primary));
    border-radius: 5px;
    color: white !important;
}
</style>