import axios from "axios";
import { isCookieEnabled, getCookie } from "tiny-cookie";

const getRequest = function(url, data, resolve, reject, timeout = 600000) {
  const config = {
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: timeout,
    headers: {
      "Content-Type": "application/json"
    }
  };
  const token = isCookieEnabled() ? getCookie("token") : "";
  if (token) {
    config.headers["Authorization"] = `JWT ${token}`;
  }
  const instance = axios.create(config);
  instance
    .get(url, data)
    .then(res => {
      resolve(res);
    })
    .catch(error => {
      // checkAuthorization(error.response.status);
      reject(error);
    });
};

const postRequest = function(url, data, resolve, reject, timeout = 10000) {
  const config = {
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: timeout,
    headers: {
      "Content-Type": "application/json"
    }
  };
  const token = isCookieEnabled() ? getCookie("token") : "";
  if (token) {
    config.headers["Authorization"] = `JWT ${token}`;
  }
  const instance = axios.create(config);
  instance
    .post(url, data)
    .then(res => {
      resolve(res);
    })
    .catch(error => {
      // checkAuthorization(error.response.status);
      reject(error);
    });
};

const deleteRequest = function(url, data, resolve, reject) {
  const config = {
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: 1000,
    headers: {
      "Content-Type": "application/json"
    }
  };
  const token = isCookieEnabled() ? getCookie("token") : "";
  if (token) {
    config.headers["Authorization"] = `JWT ${token}`;
  }
  const instance = axios.create(config);
  instance
    .delete(url, data)
    .then(res => {
      resolve(res);
    })
    .catch(error => {
      reject(error);
    });
};

const putRequest = function(url, data, resolve, reject, timeout = 1000) {
  const config = {
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: timeout,
    headers: {
      "Content-Type": "application/json"
    }
  };
  const token = isCookieEnabled() ? getCookie("token") : "";
  if (token) {
    config.headers["Authorization"] = `JWT ${token}`;
  }
  const instance = axios.create(config);
  instance
    .put(url, data)
    .then(res => {
      resolve(res);
    })
    .catch(error => {
      // checkAuthorization(error.response.status);
      reject(error);
    });
};

export default {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest
};
