import common from "Api/index";

const state = {
    missionList: [],
    discussMission: [],
    missionDocument: []
}

// Getters
const getters = {
    GET_MISSION_LIST_DATA: state => {
        return state.missionList
    },
    GET_DISCUSS_MISSION_LIST_DATA: state => {
        return state.discussMission
    },
    GET_DOCUMENT_MISSION_LIST_DATA: state => {
        return state.missionDocument
    },
}

// Actions
const actions = {
    CREATE_MISSION: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/mission/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_MISSION_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/mission/",
                payload,
                function (res) {
                    context.commit("GET_MISSION_LIST", res.data);
                    resolve(res);
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    POST_DISCUSS_MISSION: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/mission/post_discuss_mission/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_DISCUSS_MISSION_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/mission/get_discuss_mission/",
                payload,
                function (res) {
                    context.commit("GET_DISCUSS_MISSION_LIST", res.data);
                    resolve(res);
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_DOCUMENT_MISSION_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/mission/get_document_for_mission/",
                payload,
                function (res) {
                    context.commit("GET_DOCUMENT_MISSION_LIST", res.data);
                    resolve(res);
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    POST_DOCUMENT_FOR_SUMMARY: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/mission/create_document_for_conclusion/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    POST_DOCUMENT_FOR_TASK: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/mission/post_document_for_task/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    CHANGE_MISSION_STATUS: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/mission/change_mission_status/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
}

// Mutations
const mutations = {
    GET_MISSION_LIST: (state, payload) => {
        state.missionList = payload
    },
    GET_DISCUSS_MISSION_LIST: (state, payload) => {
        state.discussMission = payload
    },
    GET_DOCUMENT_MISSION_LIST: (state, payload) => {
        state.missionDocument = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}