import common from "Api/index";

const state = {
    roomMeeting: [],
    seatsList: [],
    chairMain: [],
    upload_file_data_results: [],
    upload_file_data_headers: []
}

const getters = {
    GET_ROOM_MEETING_INFO_DATA: state => {
        return state.roomMeeting;
    },
    GET_SEATS_FOR_MEETING_ROOM_DATA: state => {
        return state.seatsList;
    },
    GET_CHAIR_MAIN_DATA: state => {
        return state.chairMain;
    },
    GET_CHANGE_UPLOAD_EXCEL_DATA: state => {
        let obj = {
            'results': state.upload_file_data_results,
            'headers': state.upload_file_data_headers
        }
        return obj
    }
}

const actions = {
    GET_ROOM_MEETING_INFO: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/attendeelist/get_room_meeting_info/",
                payload,
                function (res) {
                    context.commit("GET_ROOM_MEETING_INFO", res.data);
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_SEATS_FOR_MEETING_ROOM: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/attendeelist/get_seats_for_meeting_room/",
                payload,
                function (res) {
                    context.commit("GET_SEATS_FOR_MEETING_ROOM", res.data);
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    UPDATE_USER_FOR_SEATS: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/attendeelist/update_user_for_seats/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    UPDATE_CHAIR_MAIN: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/attendeelist/update_chair_main/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_CHAIR_MAIN: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/attendeelist/get_chair_main/",
                payload,
                function (res) {
                    context.commit("GET_CHAIR_MAIN", res.data);
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    IMPORT_USER_FOR_SEATS_EXCEL: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/attendeelist/update_user_for_seats_from_excel/",
                payload,
                function (res) {
                    // context.commit("GET_CHAIR_MAIN", res.data);
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    }
}

const mutations = {
    GET_ROOM_MEETING_INFO: (context, payload) => {
        return state.roomMeeting = payload
    },
    GET_SEATS_FOR_MEETING_ROOM: (context, payload) => {
        return state.seatsList = payload
    },
    GET_CHAIR_MAIN: (context, payload) => {
        return state.chairMain = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}