import common from "Api";

const state = {
    surveyQuestions: [],
    answer: []
}

// Getters
const getters = {
    GET_SURVEYQUESTION_DATA: state => {
        return state.surveyQuestions;
    },
    GET_ANSWER_SURVEY_DATA: state => {
        return state.answer;
    },
}
// Actions
const actions = {
    GET_SURVEYQUESTION_LIST_FOR_MEETING: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/surveyquestions/get_survey_list_for_meeting/",
                payload,
                function (res) {
                    context.commit("GET_SURVEYQUESTION_LIST_FOR_MEETING", res.data)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    ADD_ANSWER_SURVEYQUESTION: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/surveyquestions/save_answer_survey/",
                payload,
                function (res) {
                    resolve(res.data)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_ANSWER_SURVEY: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/surveyquestions/get_answer_survey/",
                payload,
                function (res) {
                    context.commit("GET_ANSWER_SURVEY", res.data)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    ADD_SURVEYQUESTION: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/surveyquestions/add_survey_question_for_meeting/",
                payload,
                function (res) {
                    resolve(res.data)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
}

// Mutations
const mutations = {
    GET_SURVEYQUESTION_LIST_FOR_MEETING: (state, payload) => {
        let survey_data = payload;
        let survey_list = [];

        survey_data.forEach(value => {
            let survey_info = {
                id: value.id,
                // survey_id: value.survey,
                type_of_question_code: value.type_of_question_code,
                question_details: value.question_details,
                created_by: value.user.last_name + ' ' + value.user.first_name,
                session: value.event_session,
                date_created: value.date_created,
                date_updated: value.date_updated,
                start_datetime: value.start_datetime,
                end_datetime: value.end_datetime,
                answer_info: value.answer_info,
                user_answer: value.user_answer,
                security: value.security
            };
            survey_list.push(survey_info);
        });
        state.surveyQuestions = survey_list;

    },
    GET_ANSWER_SURVEY: (state, payload) => {
        state.answer=payload
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
