
import { languages } from './data'

const state = {
  languages,
  selectedLocale: 'vi'
}

// getters
const getters = {
  languages: state => {
    return state.languages
  },
  selectedLocale: state => {
    return state.selectedLocale
  }
}

// actions
const actions = {
  changeLanguage (context, payload) {
    context.commit('changeLanguageHandler', payload)
  }
}

// mutations
const mutations = {
  /**
    * method for setting language state
   */
  changeLanguageHandler (state, language) {
    state.selectedLocale = language
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
