/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'
// Functions
import app from './modules/app'
import language from './modules/language'
import user from './modules/user'
import event from './modules/event'
import documentation from './modules/documentation'
import opinion from './modules/opinion'
import summary from './modules/summary'
import mission from './modules/mission'
import stated from './modules/stated'
import support from './modules/support'
import attendeelist from './modules/attendeelist'
import notification from './modules/notification'
import feedback from './modules/feedback'
Vue.use(Vuex)
// Create a new store
const store = new Vuex.Store({
  modules: {
    app,
    language,
    user,
    event,
    documentation,
    opinion,
    summary,
    mission,
    stated,
    support,
    attendeelist,
    notification,
    feedback
  }
})

export default store
