import common from "Api";

// State
const state = {
    sentiments: [],
    sentiment: {},
    sentimentUserSpeaking: [],
    criticismList: [],
    lastActiveVoice: []
}

// Getters
const getters = {
    SENTIMENT_DATA: state => {
        return state.sentiments
    },
    SENTIMENT_WITH_CURRENT_USER_DATA: state => {
        return state.sentiment
    },
    GET_SENTIMENT_USER_SPEAKING_DATA: state => {
        return state.sentimentUserSpeaking
    },
    GET_CRITICISM_IN_SENTIMENT_DATA: state => {
        return state.criticismList
    },
    GET_LAST_ACTIVE_VOICE_DATA: state => {
      return state.lastActiveVoice
  },
}
// Actions
const actions = {
    CREATE_SENTIMENT_FOR_EVENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/sentiment/",
                payload,
                function (res) {
                    resolve(res.data)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_SENTIMENT_IN_EVENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/sentiment/",
                payload,
                function (res) {
                    context.commit('GET_SENTIMENT_IN_EVENT_HANDLER', res.data)
                    resolve(res.data)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_SENTIMENT_WITH_CURRENT_USER: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/sentiment/get_sentiment_with_current_user/",
                payload,
                function (res) {
                    context.commit('GET_SENTIMENT_WITH_CURRENT_USER_HANDLER', res.data)
                    resolve(res.data)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    UPDATE_PRIORITY_SENTIMENT_IN_EVENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/sentiment/update_priority_sentiment_for_event/",
                payload,
                function (res) {
                    resolve(res.data)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    UPDATE_SENTIMENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.putRequest(
                `api/v1/sentiment/${payload.id}/`,
                payload,
                function (res) {
                    resolve(res.data)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    CHAIRMAN_SECRETARY_ADD_USER_STATED: (context, payload) => {
      return new Promise((resolve, reject) => {
        common.postRequest(
          "api/v1/sentiment/chairman_and_secretary_add_user_stated/",
          payload,
          function (res) {
            resolve(res.data)
          },
          function (error) {
            reject(error)
          }
        )
      })
    },
    GET_SENTIMENT_USER_SPEAKING: (context, payload) => {
      return new Promise((resolve, reject) => {
          common.getRequest(
              "api/v1/sentiment/get_sentiment_user_speaking/",
              payload,
              function (res) {
                  context.commit('GET_SENTIMENT_USER_SPEAKING', res.data)
                  resolve(res.data)
              },
              function (error) {
                  reject(error)
              }
          )
      })
    },
    // criticism
    GET_CRITICISM_IN_SENTIMENT: (context, payload) => {
      return new Promise((resolve, reject) => {
          common.getRequest(
              "api/v1/criticism/",
              payload,
              function (res) {
                  context.commit('GET_CRITICISM_IN_SENTIMENT', res.data)
                  resolve(res.data)
              },
              function (error) {
                  reject(error)
              }
          )
      })
    },
    CREATE_CRITICISM: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/criticism/",
                payload,
                function (res) {
                    resolve(res.data)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    UPDATE_CRITICISM: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.putRequest(
                `api/v1/criticism/${payload.id}/`,
                payload,
                function (res) {
                    resolve(res.data)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_LAST_ACTIVE_VOICE: (context, payload) => {
      return new Promise((resolve, reject) => {
          common.getRequest(
              "api/v1/active_voice/get_last_active_voice/",
              payload,
              function (res) {
                  context.commit('GET_LAST_ACTIVE_VOICE', res.data)
                  resolve(res.data)
              },
              function (error) {
                  reject(error)
              }
          )
      })
    },
    CREATE_ACTIVE_VOICE: (context, payload) => {
      return new Promise((resolve, reject) => {
          common.postRequest(
              "api/v1/active_voice/",
              payload,
              function (res) {
                  resolve(res.data)
              },
              function (error) {
                  reject(error)
              }
          )
      })
    },
    UPDATE_ACTIVE_VOICE: (context, payload) => {
      return new Promise((resolve, reject) => {
          common.putRequest(
              `api/v1/active_voice/${payload.id}/`,
              payload,
              function (res) {
                  resolve(res.data)
              },
              function (error) {
                  reject(error)
              }
          )
      })
    },
}

// Mutations
const mutations = {
    GET_SENTIMENT_WITH_CURRENT_USER_HANDLER: (state, payload) => {
        state.sentiment = payload
    },
    GET_SENTIMENT_IN_EVENT_HANDLER: (state, payload) => {
        state.sentiments = payload
    },
    GET_SENTIMENT_USER_SPEAKING: (state, payload) => {
      state.sentimentUserSpeaking = payload
    },
    GET_CRITICISM_IN_SENTIMENT: (state, payload) => {
      state.criticismList = payload
    },
    GET_LAST_ACTIVE_VOICE: (state, payload) => {
      state.lastActiveVoice = payload
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
