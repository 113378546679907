import common from "Api/index";

const state = {
    summaryList:[],
    documentSummary: [],
    actionSummaryType: null
}

// Getter
const getters = {
    GET_SUMMARY_LIST_DATA: state => {
        return state.summaryList
    },
    GET_DOCUMENT_FOR_SUMMARY_DATA: state => {
        return state.documentSummary
    },
    ACTION_SUMMARY_TYPE: state => {
        return state.actionSummaryType
    }
}

// Actions
const actions = {
    CREATE_SUMMARY: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/conclusion/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    UPDATE_SUMMARY: (context, payload) => {
      return new Promise((resolve, reject) => {
        common.putRequest(
          `api/v1/conclusion/${payload.id}/`,
          payload,
          function (res) {
            resolve(res)
          },
          function (error) {
            reject(error)
          }
        )
      })
    },
    GET_SUMMARY_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/conclusion/",
                payload,
                function (res) {
                    context.commit("GET_SUMMARY_LIST", res.data);
                    resolve(res);
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    DELETE_SUMMARY: (context, payload) => {
        return new Promise((resolve, reject) => {
          common.deleteRequest(
            `api/v1/conclusion/${payload.id}/`,
            { data: payload},
            function (res) {
              resolve(res)
            },
            function (error) {
              reject(error)
            }
          )
        })
      },
    SAVE_DOCUMENT_SUMMARY: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/conclusion/save_document/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_DOCUMENT_FOR_SUMMARY:(context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/documents/get_document_for_conclusion/",
                payload,
                function (res) {
                    context.commit("GET_DOCUMENT_FOR_SUMMARY", res.data);
                    resolve(res);
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    SEND_EMAIL_FOR_MISSION_NOT_DONE:(context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/conclusion/send_email_for_task_not_done/",
                payload,
                function (res) {
                    resolve(res);
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    UPDATE_STATUS_SUMMARY:(context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/conclusion/update_status_summary/",
                payload,
                function (res) {
                    resolve(res);
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    SAVE_DOCUMENT_FOR_REPORT_MEETING: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                `api/v1/conclusion/save_document_for_report_meeting/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    SAVE_DOCUMENT_FOR_CONCLUSION: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/conclusion/save_document_for_conclusion/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
}

// Mutations
const mutations = {
    GET_SUMMARY_LIST: (state, payload) => {
        state.summaryList = payload
    },
    GET_DOCUMENT_FOR_SUMMARY: (state, payload) => {
        state.documentSummary = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}