import common from "Api/index";

// state
const state = {
    documents: [],
    documentsInEventSession: [],
    documentTabType: 1,
    documentNotes: [],
    documentsPersonal: [],
    documentsExchangeToViewerWaitingApproval: [],
    userViewDocumentExchange: []
}

// getter
const getters = {
    DOCUMENT_TAB_TYPE: state => {
        return state.documentTabType
    },
    GET_DOCUMENT_DATA: state => {
        return state.documents;
    },
    GET_DOCUMENTS_IN_EVENT_SESSION_DATA: state => {
        return state.documentsInEventSession
    },
    DOCUMENT_NOTES_DATA: state => {
        return state.documentNotes
    },
    DOCUMENT_PERSONAL_DATA: state => {
        return state.documentsPersonal
    },
    GET_DOCUMENT_EXCHANGE_TO_VIEWER_DATA: state => {
        return state.documentsExchangeToViewerWaitingApproval
    },
    GET_USER_VIEW_DOCUMENT_EXCHANGE_DATA: state => {
        return state.userViewDocumentExchange
    },
}
// action
const actions = {
    CREATE_DOCUMENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/documents/create_document_in_event_session/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_DOCUMENT_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/documents/",
                payload,
                function (res) {
                    context.commit("GET_DOCUMENT_LIST", res.data);
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_DOCUMENTS_IN_EVENT_SESSION: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                "api/v1/documents/get_documents_in_event_session/",
                payload,
                function (res) {
                    context.commit("GET_DOCUMENTS_IN_EVENT_SESSION", res.data);
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    CREATE_NOTE_IN_DOCUMENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                "api/v1/documents/create_note_in_document/",
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    DELETE_DOCUMENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.deleteRequest(
                `api/v1/documents/${payload.id}/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    REJECT_DOCUMENT_REVIEW: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                `api/v1/documents/reject_document_review/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    APPROVE_DOCUMENT_REVIEW: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                `api/v1/documents/approve_document_review/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    UPDATE_DOCUMENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.putRequest(
                `api/v1/documents/${payload.id}/update_document_in_event_session/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    SIGNED_DOCUMENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.putRequest(
                `api/v1/documents/${payload.id}/signed_document/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    UPDATE_DOCUMENT_REVIEWER: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.postRequest(
                `api/v1/documents/update_document_reviewer/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_DOCUMENT_REVIEWER: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.getRequest(
                `api/v1/documents/get_document_reviewer/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_DOCUMENT_NOTES: function (context, payload) {
        return new Promise((resolve, reject) => {
            common.getRequest(
                `api/v1/documents/get_document_notes/`,
                payload,
                function (res) {
                    context.commit('GET_DOCUMENT_NOTES_HANDLER', res.data)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_DOCUMENT_PERSONAL: function (context, payload) {
        return new Promise((resolve, reject) => {
            common.getRequest(
                `api/v1/documents/get_document_personal/`,
                payload,
                function (res) {
                    context.commit('GET_DOCUMENT_PERSONAL_HANDLER', res.data)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_DOCUMENT_APPROVER: function (context, payload) {
        return new Promise((resolve, reject) => {
            common.getRequest(
                `api/v1/documents/get_document_approver/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    UPDATE_DOCUMENT_APPROVER: function (context, payload) {
        return new Promise((resolve, reject) => {
            common.postRequest(
                `api/v1/documents/update_document_approver/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    SWITCH_DOCUMENT_FROM_PERSONAL_TO_GENERAL: function (context, payload) {
        return new Promise((resolve, reject) => {
            common.postRequest(
                `api/v1/documents/switch_document_from_personal_to_general/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    DELETE_DOCUMENT_PERSONAL: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.putRequest(
                `api/v1/documents/${payload.id}/delete_document_personal/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    POST_USER_VIEW_DOCUMENT_EXCHANGE: function (context, payload) {
        return new Promise((resolve, reject) => {
            common.postRequest(
                `api/v1/documents/post_user_view_document_exchange/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_DOCUMENT_EXCHANGE_TO_VIEWER: function (context, payload) {
        return new Promise((resolve, reject) => {
            common.getRequest(
                `api/v1/documents/get_document_exchange_to_viewer/`,
                payload,
                function (res) {
                    context.commit('GET_DOCUMENT_EXCHANGE_TO_VIEWER_HANDLER', res.data)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_USER_VIEW_DOCUMENT_EXCHANGE: function (context, payload) {
        return new Promise((resolve, reject) => {
            common.getRequest(
                `api/v1/documents/get_user_view_document_exchange/`,
                payload,
                function (res) {
                    context.commit('GET_USER_VIEW_DOCUMENT_EXCHANGE_HANDLER', res.data)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    POST_DOCUMENT_EXCHANGE_TO_VIEWER_STATUS: function (context, payload) {
      return new Promise((resolve, reject) => {
          common.postRequest(
              `api/v1/documents/approval_or_reject_document_exchange_to_viewer/`,
              payload,
              function (res) {
                  resolve(res)
              },
              function (error) {
                  reject(error)
              }
          )
      })
  },
    DELETE_EVENT_SESSION: (context, payload) => {
        return new Promise((resolve, reject) => {
            common.deleteRequest(
                `api/v1/agendas/${payload.id}/`,
                {data: payload},
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    }
}

// mutations
const mutations = {
    GET_DOCUMENT_NOTES_HANDLER: (state, payload) => {
        return state.documentNotes = payload
    },
    GET_DOCUMENT_LIST: (context, payload) => {
        return state.documents = payload
    },
    GET_DOCUMENTS_IN_EVENT_SESSION: (context, payload) => {
        return state.documentsInEventSession = payload
    },
    GET_DOCUMENT_PERSONAL_HANDLER: (state, payload) => {
        return state.documentsPersonal = payload
    },
    GET_DOCUMENT_EXCHANGE_TO_VIEWER_HANDLER: (state, payload) => {
        return state.documentsExchangeToViewerWaitingApproval = payload
    },
    GET_USER_VIEW_DOCUMENT_EXCHANGE_HANDLER: (state, payload) => {
      return state.userViewDocumentExchange = payload
  },
}

export default {
    state,
    getters,
    actions,
    mutations
}