import { Promise } from "es6-promise";
const state = {
  drawer: true,
  snackbar: [],
  inAMeeting: false,
  callbackApiAttendance: false,
  attendanceSetInterval: null,
  callbackApiFeedback: false,
  feedbackSetInterval: null
}
const getters = {
  DRAWER_CHANGE: state => {
    return state.drawer
  },
  GET_SNACK_BAR: state => {
    return state.snackbar;
  },
  IN_A_MEETING: state => {
    return state.inAMeeting
  },
  CALLBACK_API_ATTENDANCE: state => {
    return state.callbackApiAttendance
  },
  CALLBACK_API_FEEDBACK: state => {
    return state.callbackApiFeedback
  }
}
const actions = {
  ON_SHOW_TOAST: (context, payload) => {
    return new Promise((resolve) => {
      const id = Math.floor(1000000 * Math.random()).toString();
      const data = {
        id: id,
        message: payload.message,
        styleType: payload.styleType
      };
      context.commit("ON_SHOW_TOAST", data);
      resolve();
    });
  },
  DELETE_TOAST: (context, payload) => {
    return new Promise((resolve) => {
      const data = payload;
      context.commit("DELETE_TOAST", data);
      resolve();
    });
  },
}
const mutations = {
  ON_SHOW_TOAST: (state, payload) => {
    const data = payload;
    state.snackbar.push(data);
  },
  DELETE_TOAST: (state, payload) => {
    const data = payload;
    state.snackbar.forEach((v, i, a) => {
      if (data.id === v.id) {
        a.splice(i, 1);
        return true;
      }
    });
  },
}
export default {
  mutations,
  state,
  getters,
  actions
}
